import React, { useEffect } from 'react';
import {
    Box, Container, Heading, Text, VStack, UnorderedList, ListItem,
    useColorModeValue,
    Button
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const AboutUs = () => {
    const bgColor = useColorModeValue('green.50', 'green.900');
    const textColor = useColorModeValue('gray.800', 'white');
    const buttonColorScheme = useColorModeValue('green', 'blue');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box bg={"#EFEDDC"} minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="750px" py={12}>
                <VStack spacing={6} align="stretch">
                    <Heading as="h1" size="2xl" color={textColor}>About Us</Heading>
                    <Text fontWeight="bold" color={textColor}>Connecting Communities, Empowering Consumers, Supporting Local Producers</Text>
                    <Text color={textColor}>
                        At Life Essentials Club, people are revolutionizing the way they access life's essentials. Our club's mission is to connect you with life essential products and services in a secure, fair, and uninhibited manner, cutting out unnecessary middlemen and bringing you as close to the source of quality, healthy and ethical products as possible.
                    </Text>

                    <Heading as="h2" size="xl" color={textColor}>Our Core Club Values</Heading>

                    <VStack spacing={4} align="stretch">
                        <Heading as="h3" size="lg" color={textColor}>1. Direct Connections</Heading>
                        <Text color={textColor}>We believe in the power of direct relationships. By bringing consumers together directly with producers and service providers, we facilitate transparency, fairness, and resilience for everyone involved.</Text>

                        <Heading as="h3" size="lg" color={textColor}>2. Security and Trust</Heading>
                        <Text color={textColor}>Integrity is our priority. We've integrated with secure platforms so that members can shop with confidence, knowing that personal information and transactions are protected. We offer alternative mediums of payment to provide our members with economic resilience.</Text>

                        <Heading as="h3" size="lg" color={textColor}>3. Fairness for All</Heading>
                        <Text color={textColor}>We're committed to creating a level playing field. Our club aims to provide fair pricing for consumers and innovative ways for all of us to foster sustainable economics for life's essentials.</Text>

                        <Heading as="h3" size="lg" color={textColor}>4. Community Empowerment</Heading>
                        <Text color={textColor}>We're not just a marketplace; we're a club and a community. By bringing together consumers and producers, we're fostering relationships that go beyond transactions. Through developing relationships, building trust and wish lists, together we believe we can achieve great feats.</Text>

                        <Heading as="h3" size="lg" color={textColor}>5. Removing Contaminants & Poisons from Our Food Chain</Heading>
                        <Text color={textColor}>We harness collective buying power to influence food producers. Our goal is to remove plastic packaging and harmful additives that negatively impact health.</Text>
                    </VStack>

                    <Heading as="h2" size="xl" color={textColor}>How We're Different</Heading>

                    <VStack spacing={4} align="stretch">
                        <Heading as="h3" size="lg" color={textColor}>Collective Farmer Support</Heading>
                        <Text color={textColor}>We understand the challenges faced by producers, especially small-scale and local businesses. That's why we've introduced a pre-order system that allows you to pledge your business with producers before production begins. This approach:</Text>
                        <UnorderedList pl={4} color={textColor}>
                            <ListItem>Reduces financial costs for producers</ListItem>
                            <ListItem>Ensures there's a need for products before they're grown or made</ListItem>
                            <ListItem>Allows producers to plan more effectively</ListItem>
                            <ListItem>Gives you access to unique, made-to-order items</ListItem>
                            <ListItem>Reduces economic waste</ListItem>
                            <ListItem>Provides better cost effectiveness for life essentials</ListItem>
                        </UnorderedList>

                        <Heading as="h3" size="lg" color={textColor}>Power in Numbers: Group-Buys</Heading>
                        <Text color={textColor}>We believe in the power of collective purchasing. The club's innovative group-buy feature allows you to:</Text>
                        <UnorderedList pl={4} color={textColor}>
                            <ListItem>Pool your purchasing power with other members</ListItem>
                            <ListItem>Access bulk discounts on essential items</ListItem>
                            <ListItem>Lower the cost of high-quality products</ListItem>
                            <ListItem>Support producers by guaranteeing larger order volumes</ListItem>
                        </UnorderedList>
                        <Text color={textColor}>By participating in group-buys, you're not just saving money – you're part of a movement that's making quality essentials more accessible to everyone.</Text>
                    </VStack>

                    <Heading as="h2" size="xl" color={textColor}>Our Vision</Heading>
                    <Text color={textColor}>We know we live in a world where access to ethical and healthy life essentials is not a privilege but a right. We live in a world where producers must be fairly compensated for their work, and consumers need access to high-quality products at fair prices. We are living in a world where communities come together to support each other, creating a more sustainable and equitable marketplace.</Text>
                    <Text color={textColor}>Join us in this journey. Together, we're not just shopping – we're shaping a better future for our healthy children to inherit an even more beautiful world.</Text>
                    <Text color={textColor}>Get life essentials for you and your community.</Text>



                    <Box textAlign="center" pt={8}>
                        <Button
                            as={RouterLink}
                            to="/trialmembership"
                            colorScheme={buttonColorScheme}
                            size="lg"
                            fontWeight="bold"
                        >
                            Start Your Trial Membership
                        </Button>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default AboutUs;