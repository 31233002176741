import React, { useState } from 'react';
import {
    Box, Button, Container, Heading, Text, VStack,
    FormControl, Input, useToast, Image, Stack,
    Icon, Badge, useColorModeValue, List, ListItem,
    SimpleGrid, Flex, Divider, Circle, chakra
} from '@chakra-ui/react';
import {
    CheckIcon, StarIcon, LockIcon, ArrowForwardIcon
} from '@chakra-ui/icons';
import {
    FaHeart, FaHeartBroken, FaSyncAlt, FaMagic,
    FaUserFriends, FaRegLightbulb
} from 'react-icons/fa';
import { gql, useMutation } from '@apollo/client';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const CREATE_LEAD = gql`
  mutation CreateLead($email: String!, $name: String!) {
    createLead(email: $email, name: $name) {
      id
      email
      name
    }
  }
`;

const LeadMagnetPage = () => {
    const [formData, setFormData] = useState({ name: '', email: '' });
    const toast = useToast();

    const bgMain = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const accentColor = useColorModeValue('purple.500', 'purple.300');
    const textColor = useColorModeValue('gray.700', 'gray.200');
    const borderColor = useColorModeValue('purple.100', 'purple.700');

    const [createLead, { loading }] = useMutation(CREATE_LEAD, {
        onCompleted: () => {
            console.log('✅ Lead captured successfully');
            toast({
                title: "Your Journey Begins!",
                description: "Your relationship guide is on its way to your inbox.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setTimeout(() => window.location.href = '/webinar', 2000);
        },
        onError: (error) => {
            console.error('❌ Lead capture error:', error);
            toast({
                title: "Connection Error",
                description: "We couldn't process your request. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        console.log('📝 Processing new relationship guide request:', formData);

        if (!formData.name || !formData.email) {
            toast({
                title: "Just a moment",
                description: "Please share your name and email to receive the guide",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        await createLead({ variables: formData });
    };

    return (
        <Box>
            <NavbarWithCallToAction />

            {/* Hero Section - Emotional Story */}
            <Box
                position="relative"
                py={20}
                bg="purple.600"
                color="white"
                overflow="hidden"
            >
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bgGradient="linear(to-r, purple.600, pink.500)"
                    opacity={0.9}
                />
                <Container maxW="container.xl" position="relative">
                    <SimpleGrid columns={{ base: 1, lg: 2 }} gap={16} alignItems="center">
                        <Box>
                            <Badge
                                colorScheme="pink"
                                fontSize="sm"
                                mb={4}
                                px={3}
                                py={1}
                                borderRadius="full"
                            >
                                FREE RELATIONSHIP SECRET
                            </Badge>
                            <Heading
                                as="h1"
                                fontSize={{ base: "4xl", md: "5xl" }}
                                mb={6}
                                lineHeight="shorter"
                            >
                                Why Do Some Couples Stay Madly in Love, While Others Drift Apart?
                            </Heading>
                            <Text fontSize="xl" mb={8} opacity={0.9}>
                                After studying 1,000+ couples for over 20 years, we discovered 3 hidden
                                psychological triggers that keep love alive forever. And now, for the first
                                time ever, we're revealing them all in our free guide.
                            </Text>
                            <Button
                                size="lg"
                                bg="white"
                                color="purple.600"
                                _hover={{ bg: 'gray.100' }}
                                onClick={() => window.scrollTo({ top: 800, behavior: 'smooth' })}
                            >
                                Reveal The Secrets
                            </Button>
                        </Box>
                        <Box position="relative">
                            <Circle
                                size="400px"
                                bg="whiteAlpha.200"
                                position="absolute"
                                top="-20px"
                                right="-20px"
                            />
                            <Image
                                src="/couple-happy.jpg"
                                alt="Happy Couple"
                                borderRadius="xl"
                                shadow="2xl"
                                position="relative"
                            />
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Statistics Section */}
            <Box py={16} bg={cardBg}>
                <Container maxW="container.xl">
                    <SimpleGrid columns={{ base: 1, md: 3 }} gap={8}>
                        {[
                            { number: "94%", label: "Success Rate", icon: FaHeart },
                            { number: "3", label: "Hidden Triggers", icon: FaRegLightbulb },
                            { number: "20+", label: "Years Research", icon: FaUserFriends }
                        ].map((stat, idx) => (
                            <VStack
                                key={idx}
                                p={8}
                                bg={bgMain}
                                borderRadius="xl"
                                spacing={4}
                                shadow="lg"
                            >
                                <Circle size="60px" bg="purple.500" color="white">
                                    <Icon as={stat.icon} w={6} h={6} />
                                </Circle>
                                <Text fontSize="4xl" fontWeight="bold" color={accentColor}>
                                    {stat.number}
                                </Text>
                                <Text fontSize="lg" color={textColor}>
                                    {stat.label}
                                </Text>
                            </VStack>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Before/After Journey */}
            <Box py={20} bg={bgMain}>
                <Container maxW="container.xl">
                    <Heading textAlign="center" mb={16}>The Relationship Journey</Heading>
                    <SimpleGrid columns={{ base: 1, md: 2 }} gap={12}>
                        <Box
                            p={8}
                            bg="red.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="red.100"
                        >
                            <VStack align="start" spacing={6}>
                                <Icon as={FaHeartBroken} w={8} h={8} color="red.500" />
                                <Heading size="md" color="red.700">Before The Triggers</Heading>
                                <List spacing={4}>
                                    {[
                                        "Growing emotional distance",
                                        "Communication feels forced",
                                        "Love feels like it's fading",
                                        "Arguments over small things",
                                        "Feeling stuck and frustrated"
                                    ].map((item, idx) => (
                                        <ListItem key={idx} display="flex" alignItems="center">
                                            <Icon as={FaHeartBroken} w={4} h={4} color="red.400" mr={2} />
                                            <Text color="red.700">{item}</Text>
                                        </ListItem>
                                    ))}
                                </List>
                            </VStack>
                        </Box>
                        <Box
                            p={8}
                            bg="green.50"
                            borderRadius="xl"
                            border="1px"
                            borderColor="green.100"
                        >
                            <VStack align="start" spacing={6}>
                                <Icon as={FaHeart} w={8} h={8} color="green.500" />
                                <Heading size="md" color="green.700">After The Triggers</Heading>
                                <List spacing={4}>
                                    {[
                                        "Deep emotional connection",
                                        "Effortless communication",
                                        "Love grows stronger daily",
                                        "Peaceful resolution of differences",
                                        "Exciting and fulfilling relationship"
                                    ].map((item, idx) => (
                                        <ListItem key={idx} display="flex" alignItems="center">
                                            <Icon as={CheckIcon} w={4} h={4} color="green.400" mr={2} />
                                            <Text color="green.700">{item}</Text>
                                        </ListItem>
                                    ))}
                                </List>
                            </VStack>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Opt-in Section */}
            <Box
                py={20}
                bg="purple.600"
                color="white"
                position="relative"
                overflow="hidden"
            >
                <Container maxW="container.lg" position="relative">
                    <VStack spacing={8} align="center" maxW="600px" mx="auto">
                        <Circle
                            size="200px"
                            bg="whiteAlpha.100"
                            position="absolute"
                            top="-100px"
                            left="-100px"
                        />
                        <Circle
                            size="300px"
                            bg="whiteAlpha.100"
                            position="absolute"
                            bottom="-150px"
                            right="-150px"
                        />
                        <Badge
                            colorScheme="pink"
                            px={3}
                            py={1}
                            borderRadius="full"
                        >
                            LIMITED TIME OFFER
                        </Badge>
                        <Heading textAlign="center">
                            Get Your Free Guide + Special Bonuses
                        </Heading>
                        <Text fontSize="lg" textAlign="center" opacity={0.9}>
                            Discover the scientifically-proven triggers that make love last forever,
                            plus receive exclusive access to our relationship masterclass.
                        </Text>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <VStack spacing={4} w="full">
                                <FormControl isRequired>
                                    <Input
                                        bg="whiteAlpha.200"
                                        border="none"
                                        placeholder="Your Name"
                                        size="lg"
                                        _placeholder={{ color: 'whiteAlpha.600' }}
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl isRequired>
                                    <Input
                                        bg="whiteAlpha.200"
                                        border="none"
                                        placeholder="Your Email"
                                        size="lg"
                                        type="email"
                                        _placeholder={{ color: 'whiteAlpha.600' }}
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    size="lg"
                                    bg="white"
                                    color="purple.600"
                                    w="full"
                                    _hover={{ bg: 'gray.100' }}
                                    isLoading={loading}
                                    loadingText="Preparing Your Guide..."
                                >
                                    Get Instant Access
                                </Button>
                            </VStack>
                        </form>
                        <Text fontSize="sm" textAlign="center">
                            <Icon as={LockIcon} mr={2} />
                            Your information is secure and will never be shared
                        </Text>
                    </VStack>
                </Container>
            </Box>

            <FooterWithFourColumns />
        </Box>
    );
};

export default LeadMagnetPage;