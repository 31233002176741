import { HTMLChakraProps, chakra, Text } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';

export const Logo = (props: HTMLChakraProps<'img'>) => (
  // Use the logo from /logo.png
  <>
    <Text
      fontSize="2xl"
      fontWeight="bold"
      color="green.600"
      fontFamily="'Playfair Display', serif"
      letterSpacing="wide"
      {...props}
    >
      LifeEssentials.Club
    </Text>

    {/* <Image
      src="/logo.png"
      alt="Logo"
      height="80px"
      width="auto"
      {...props}
    /> */}
  </>
);

export default Logo;
