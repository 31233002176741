import React, { useState, useEffect } from 'react';
import {
    Box, Button, Container, Heading, Text, VStack, HStack, SimpleGrid,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    FormControl, FormLabel, Input, useToast, Image, Stack, Flex, Icon,
    Badge, Avatar, useColorModeValue, List, ListItem, ListIcon, Progress,
    Stat, StatLabel, StatNumber, StatHelpText, StatArrow
} from '@chakra-ui/react';
import {
    CheckCircleIcon, CalendarIcon, TimeIcon, StarIcon,
    WarningIcon, ArrowForwardIcon
} from '@chakra-ui/icons';
import {
    FaHeart, FaLightbulb, FaUsers, FaLock, FaUserFriends,
    FaRegClock, FaGift, FaChalkboardTeacher
} from 'react-icons/fa';
import { gql, useMutation } from '@apollo/client';

import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';

console.log('🎥 Rendering Webinar Invite Page');

const CREATE_WEBINAR_REGISTRATION = gql`
  mutation AddContact($fName: String!, $lName: String!, $phoneNumber: String!, $email: String!) {
    addContactToCellcast(
      firstName: $fName
      lastName: $lName
      phoneNumber: $phoneNumber
      email: $email
    )
  }
`;
const WebinarInvitePage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        fName: '',
        lName: '',
        phoneNumber: '',
        email: ''
    });

    const [createRegistration, { loading: mutationLoading }] = useMutation(
        CREATE_WEBINAR_REGISTRATION,
        {
            onCompleted: (data) => {
                console.log('✅ Webinar registration successful:', data);
                toast({
                    title: "Registration Successful!",
                    description: "Webinar access details will be sent to your mobile number shortly.",
                    status: "success",
                    duration: 50000,
                    isClosable: true,
                });
                setIsModalOpen(false);
            },
            onError: (error) => {
                console.error('❌ Webinar registration error:', error);
                toast({
                    title: "Registration Failed",
                    description: error.message || "Please try again later.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    );

    const toast = useToast();
    // const bgColor = useColorModeValue('blue.50', 'blue.900');
    // const cardBgColor = useColorModeValue('white', 'gray.800');
    // const accentColor = useColorModeValue('blue.500', 'blue.300');
    // const textColor = useColorModeValue('gray.600', 'gray.200');

    // Updated color scheme to match purple theme
    const bgColor = useColorModeValue('purple.50', 'purple.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const accentColor = useColorModeValue('purple.500', 'purple.300');
    const textColor = useColorModeValue('gray.600', 'gray.200');
    const borderColor = useColorModeValue('purple.100', 'purple.700');





    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    const [spotsLeft, setSpotsLeft] = useState(47); // Start with 47 spots left out of 50
    const totalSpots = 50;

    // Calculate countdown to November 14th
    useEffect(() => {
        const calculateTimeLeft = () => {
            const webinarDate = new Date('2024-11-14T19:00:00'); // 7 PM on Nov 14th
            const difference = webinarDate.getTime() - new Date().getTime();

            if (difference > 0) {
                setTimeLeft({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                });
            }
        };

        const timer = setInterval(calculateTimeLeft, 1000);
        return () => clearInterval(timer);
    }, []);

    // Add countdown display component
    const CountdownDisplay = () => (
        <HStack spacing={4} justify="center" pt={4}>
            {Object.entries(timeLeft).map(([unit, value]) => (
                <Box key={unit} textAlign="center" p={4} bg="blue.500" borderRadius="md" minW="100px">
                    <Text fontSize="2xl" fontWeight="bold" color="white">
                        {value.toString().padStart(2, '0')}
                    </Text>
                    <Text color="white" textTransform="uppercase" fontSize="sm">
                        {unit}
                    </Text>
                </Box>
            ))}
        </HStack>
    );

    const handleRegistration = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('📝 Starting webinar registration:', formData);

        if (!formData.fName || !formData.lName || !formData.phoneNumber || !formData.email) {
            console.warn('⚠️ Validation failed: Missing required fields');
            toast({
                title: "Required Fields Missing",
                description: "Please fill in all fields to register.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            console.log('🔄 Submitting webinar registration...');
            const response = await createRegistration({
                variables: {
                    fName: formData.fName,
                    lName: formData.lName,
                    phoneNumber: formData.phoneNumber,
                    email: formData.email
                }
            });

            console.log('✅ Registration response:', response);

        } catch (error: any) {
            console.error('❌ Registration failed:', error);

            // Improved error handling
            const errorMessage = error.graphQLErrors?.[0]?.message ||
                error.networkError?.message ||
                'An unexpected error occurred';

            toast({
                title: "Registration Failed",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };
    const webinarHighlights = [
        {
            title: "The Science of Connection",
            description: "Discover breakthrough research on what makes relationships thrive",
            icon: FaLightbulb
        },
        {
            title: "Communication Secrets",
            description: "Learn the hidden patterns that make or break relationships",
            icon: FaUsers
        },
        {
            title: "Emotional Intelligence",
            description: "Master the art of emotional attunement in relationships",
            icon: FaHeart
        }
    ];

    const experts = [
        {
            name: "Sun",
            role: "Relationship Expert & Clinical Psychologist",
            image: "/placeholder/expert1.jpg",
            credentials: "20+ years experience in couples therapy"
        }
    ];

    return (
        <Box>
            <NavbarWithCallToAction />


            {/* Updated Urgency Banner */}
            <Box bg="purple.600" py={2}>
                <Container maxW="container.xl">
                    <Text color="white" textAlign="center" fontSize="lg" fontWeight="medium">
                        <Icon as={WarningIcon} mr={2} />
                        Only {spotsLeft} spots remaining for this exclusive masterclass!
                    </Text>
                </Container>
            </Box>

            {/* Updated Hero Section */}
            <Box bg={bgColor} pt={20} pb={16}>
                <Container maxW="container.xl">
                    <Stack direction={{ base: 'column', lg: 'row' }} spacing={8} alignItems="center">
                        <Box flex={1}>
                            <Badge colorScheme="purple" fontSize="sm" mb={4}>
                                Limited Time Masterclass
                            </Badge>
                            <Heading
                                as="h1"
                                size="2xl"
                                mb={6}
                                bgGradient="linear(to-r, purple.400, purple.600)"
                                bgClip="text"
                            >
                                "The 3 Hidden Triggers That Make Love Last: Science-Based Secrets to Deeper Connection"
                            </Heading>

                            {/* Updated Webinar Details Box */}
                            <Box
                                mb={6}
                                p={{ base: 4, md: 6 }}
                                bg={cardBgColor}
                                borderRadius="xl"
                                border="1px"
                                borderColor={borderColor}
                                shadow="xl"
                            >
                                <Stack
                                    direction={{ base: 'column', md: 'row' }}
                                    spacing={{ base: 4, md: 8 }}
                                    justify="space-between"
                                    width="full"
                                >
                                    <VStack align={{ base: 'stretch', md: 'start' }} spacing={4}>
                                        <Text fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }} color={accentColor}>
                                            <Icon as={CalendarIcon} mr={2} />
                                            November 14th, 2024
                                        </Text>
                                        <Text fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }} color={accentColor}>
                                            <Icon as={TimeIcon} mr={2} />
                                            7:00 PM AEST
                                        </Text>
                                    </VStack>
                                    <VStack align={{ base: 'stretch', md: 'start' }} spacing={4}>
                                        <Text fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }} color="red.500">
                                            <Icon as={FaUserFriends} mr={2} />
                                            Only {spotsLeft} Spots Left
                                        </Text>
                                        <Text color="green.500" fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }}>
                                            <Icon as={FaGift} mr={2} />
                                            Free Bonus Materials
                                        </Text>
                                    </VStack>
                                </Stack>
                            </Box>

                            {/* Updated Countdown Timer */}
                            <Box mb={6}>
                                <Text
                                    fontSize={{ base: 'lg', md: 'xl' }}
                                    fontWeight="bold"
                                    mb={4}
                                    color={accentColor}
                                    textAlign="center"
                                >
                                    Registration Closes In:
                                </Text>
                                <SimpleGrid
                                    columns={{ base: 2, md: 4 }}
                                    spacing={4}
                                    pt={4}
                                    width="full"
                                >
                                    {Object.entries(timeLeft).map(([unit, value]) => (
                                        <Box
                                            key={unit}
                                            textAlign="center"
                                            p={{ base: 3, md: 4 }}
                                            bg="purple.500"
                                            borderRadius="xl"
                                            shadow="lg"
                                            transform="auto"
                                            _hover={{ transform: 'scale(1.05)' }}
                                            transition="all 0.2s"
                                        >
                                            <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold" color="white">
                                                {value.toString().padStart(2, '0')}
                                            </Text>
                                            <Text color="white" textTransform="uppercase" fontSize={{ base: 'xs', md: 'sm' }}>
                                                {unit}
                                            </Text>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>

                            {/* Updated Progress Bar */}
                            <Box mb={8} px={{ base: 2, md: 0 }}>
                                <Text fontSize={{ base: 'sm', md: 'md' }} mb={2} color={textColor}>
                                    Registration: {spotsLeft} spots remaining
                                </Text>
                                <Progress
                                    value={((totalSpots - spotsLeft) / totalSpots) * 100}
                                    colorScheme="purple"
                                    size="sm"
                                    borderRadius="full"
                                    hasStripe
                                    isAnimated
                                />
                            </Box>

                            <Button
                                size="lg"
                                colorScheme="purple"
                                onClick={() => setIsModalOpen(true)}
                                rightIcon={<ArrowForwardIcon />}
                                width="full"
                                py={{ base: 6, md: 7 }}
                                fontSize={{ base: 'md', md: 'lg' }}
                                shadow="xl"
                                _hover={{
                                    transform: 'translateY(-2px)',
                                    shadow: '2xl',
                                }}
                                transition="all 0.2s"
                            >
                                Reserve Your Spot Now (Free)
                            </Button>
                        </Box>
                        <Box flex={1}>
                            <Image
                                src="/course_images/relationships/webinar.png"
                                alt="Couple discovering relationship secrets"
                                borderRadius="xl"
                                shadow="2xl"
                                transform="auto"
                                _hover={{ transform: 'scale(1.02)' }}
                                transition="all 0.2s"
                            />
                        </Box>
                    </Stack>
                </Container>
            </Box>

            {/* Updated Bonus Section */}
            <Box py={12} bg="purple.50">
                <Container maxW="container.xl">
                    <Heading
                        textAlign="center"
                        mb={12}
                        bgGradient="linear(to-r, purple.400, purple.600)"
                        bgClip="text"
                    >
                        Free Bonuses (Worth $297)
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                        <Box p={6} bg="white" borderRadius="lg" shadow="md">
                            <Icon as={FaGift} w={8} h={8} color="purple.500" mb={4} />
                            <Heading size="md" mb={2}>Free Relationship Assessment</Heading>
                            <Text>Worth $97 - Yours free when you attend the webinar</Text>
                        </Box>
                        <Box p={6} bg="white" borderRadius="lg" shadow="md">
                            <Icon as={FaChalkboardTeacher} w={8} h={8} color="purple.500" mb={4} />
                            <Heading size="md" mb={2}>Live Q&A Session</Heading>
                            <Text>Get your specific questions answered live</Text>
                        </Box>
                        <Box p={6} bg="white" borderRadius="lg" shadow="md">
                            <Icon as={FaRegClock} w={8} h={8} color="purple.500" mb={4} />
                            <Heading size="md" mb={2}>48-Hour Replay Access</Heading>
                            <Text>Can't make it live? We've got you covered</Text>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Keep existing sections but add social proof */}
            <Box py={8} bg="gray.50">
                <Container maxW="container.xl">
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                        <Stat>
                            <StatLabel>Success Rate</StatLabel>
                            <StatNumber>94%</StatNumber>
                            <StatHelpText>
                                <StatArrow type="increase" />
                                Of couples improve their relationship
                            </StatHelpText>
                        </Stat>
                        <Stat>
                            <StatLabel>Participants</StatLabel>
                            <StatNumber>10,000+</StatNumber>
                            <StatHelpText>
                                Couples helped worldwide
                            </StatHelpText>
                        </Stat>
                        <Stat>
                            <StatLabel>Satisfaction</StatLabel>
                            <StatNumber>4.9/5</StatNumber>
                            <StatHelpText>
                                Average rating from attendees
                            </StatHelpText>
                        </Stat>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* What You'll Discover Section */}
            <Box py={16}>
                <Container maxW="container.xl">
                    <Heading textAlign="center" mb={12}>What You'll Discover</Heading>
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                        {webinarHighlights.map((highlight, index) => (
                            <Box
                                key={index}
                                p={6}
                                borderRadius="lg"
                                bg={cardBgColor}
                                shadow="md"
                            >
                                <Icon
                                    as={highlight.icon}
                                    w={10}
                                    h={10}
                                    color={accentColor}
                                    mb={4}
                                />
                                <Heading size="md" mb={4}>{highlight.title}</Heading>
                                <Text color={textColor}>{highlight.description}</Text>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Expert Section */}
            <Box py={16} bg={useColorModeValue('gray.50', 'gray.900')}>
                <Container maxW="container.xl">
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} alignItems="center">
                        <Box>
                            <Heading mb={6}>Meet Your Expert Guide</Heading>
                            <Text fontSize="lg" mb={4}>
                                Sun is a renowned relationship expert who has helped thousands of couples transform their relationships using science-based methods.
                            </Text>
                            <List spacing={3}>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color={accentColor} />
                                    Featured in Psychology Today & The New York Times
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color={accentColor} />
                                    Author of "The Connection Code"
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color={accentColor} />
                                    20+ years of clinical experience
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <Image
                                src="/webinar/expert-profile.jpg"
                                alt="Sun"
                                borderRadius="xl"
                                shadow="lg"
                            />
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Registration Modal */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                motionPreset="slideInBottom"
                size="xl"
            >
                <ModalOverlay
                    bg="blackAlpha.700"
                    backdropFilter="blur(5px)"
                />
                <ModalContent
                    bgGradient="linear(to-b, white, purple.50)"
                    borderRadius="xl"
                    shadow="2xl"
                    p={6}
                >
                    <ModalCloseButton
                        size="lg"
                        color="purple.500"
                        _hover={{
                            bg: 'purple.50',
                            transform: 'rotate(90deg)'
                        }}
                        transition="all 0.2s"
                    />

                    <Box textAlign="center" mb={6}>
                        <Badge
                            colorScheme="purple"
                            fontSize="sm"
                            mb={3}
                            px={3}
                            py={1}
                            borderRadius="full"
                        >
                            Limited Time Offer
                        </Badge>
                        <Heading
                            size="lg"
                            bgGradient="linear(to-r, purple.400, purple.600)"
                            bgClip="text"
                            mb={2}
                        >
                            Reserve Your Exclusive Spot
                        </Heading>
                        <Text color="gray.600" fontSize="lg">
                            Join {totalSpots - spotsLeft} others who have already secured their place
                        </Text>
                    </Box>

                    <Box mb={6} p={4} bg="purple.50" borderRadius="lg">
                        <HStack spacing={4} justify="center">
                            <VStack>
                                <Icon as={CalendarIcon} w={6} h={6} color="purple.500" />
                                <Text fontWeight="bold" color="purple.700">Nov 14th</Text>
                            </VStack>
                            <VStack>
                                <Icon as={TimeIcon} w={6} h={6} color="purple.500" />
                                <Text fontWeight="bold" color="purple.700">7:00 PM AEST</Text>
                            </VStack>
                            <VStack>
                                <Icon as={FaGift} w={6} h={6} color="purple.500" />
                                <Text fontWeight="bold" color="purple.700">Free Bonuses</Text>
                            </VStack>
                        </HStack>
                    </Box>

                    <form onSubmit={handleRegistration}>
                        <VStack spacing={6}>
                            <SimpleGrid columns={2} spacing={6} width="full">
                                <FormControl isRequired>
                                    <FormLabel color="gray.700">First Name</FormLabel>
                                    <Input
                                        onChange={(e) => setFormData({ ...formData, fName: e.target.value })}
                                        bg="white"
                                        borderColor="purple.200"
                                        _hover={{ borderColor: 'purple.300' }}
                                        _focus={{
                                            borderColor: 'purple.500',
                                            boxShadow: '0 0 0 1px purple.500'
                                        }}
                                        placeholder="Enter your first name"
                                        size="lg"
                                    />
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel color="gray.700">Last Name</FormLabel>
                                    <Input
                                        onChange={(e) => setFormData({ ...formData, lName: e.target.value })}
                                        bg="white"
                                        borderColor="purple.200"
                                        _hover={{ borderColor: 'purple.300' }}
                                        _focus={{
                                            borderColor: 'purple.500',
                                            boxShadow: '0 0 0 1px purple.500'
                                        }}
                                        placeholder="Enter your last name"
                                        size="lg"
                                    />
                                </FormControl>
                            </SimpleGrid>

                            <FormControl isRequired>
                                <FormLabel color="gray.700">Phone Number</FormLabel>
                                <Input
                                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                                    bg="white"
                                    borderColor="purple.200"
                                    _hover={{ borderColor: 'purple.300' }}
                                    _focus={{
                                        borderColor: 'purple.500',
                                        boxShadow: '0 0 0 1px purple.500'
                                    }}
                                    placeholder="Enter your phone number"
                                    size="lg"
                                />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel color="gray.700">Email Address</FormLabel>
                                <Input
                                    type="email"
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    bg="white"
                                    borderColor="purple.200"
                                    _hover={{ borderColor: 'purple.300' }}
                                    _focus={{
                                        borderColor: 'purple.500',
                                        boxShadow: '0 0 0 1px purple.500'
                                    }}
                                    placeholder="Enter your email address"
                                    size="lg"
                                />
                            </FormControl>

                            <VStack spacing={4} width="full">
                                <Button
                                    type="submit"
                                    colorScheme="purple"
                                    width="full"
                                    size="lg"
                                    height="60px"
                                    fontSize="lg"
                                    isLoading={mutationLoading}
                                    loadingText="Reserving Your Spot..."
                                    shadow="xl"
                                    _hover={{
                                        transform: 'translateY(-2px)',
                                        shadow: '2xl',
                                    }}
                                    transition="all 0.2s"
                                >
                                    {mutationLoading ? 'Securing Your Spot...' : 'Reserve My Spot Now'}
                                </Button>

                                <HStack spacing={2} fontSize="sm" color="gray.600">
                                    <Icon as={FaLock} />
                                    <Text>Your information is secure and will never be shared</Text>
                                </HStack>
                            </VStack>
                        </VStack>
                    </form>

                    {/* Bonus Preview */}
                    <Box mt={8} p={4} bg="purple.50" borderRadius="lg">
                        <Text fontWeight="bold" color="purple.700" mb={2}>
                            You'll Receive Instant Access To:
                        </Text>
                        <List spacing={3}>
                            <ListItem>
                                <HStack>
                                    <Icon as={CheckCircleIcon} color="green.500" />
                                    <Text>Free Relationship Assessment (Worth $97)</Text>
                                </HStack>
                            </ListItem>
                            <ListItem>
                                <HStack>
                                    <Icon as={CheckCircleIcon} color="green.500" />
                                    <Text>Live Q&A Session Access</Text>
                                </HStack>
                            </ListItem>
                            <ListItem>
                                <HStack>
                                    <Icon as={CheckCircleIcon} color="green.500" />
                                    <Text>48-Hour Replay Access</Text>
                                </HStack>
                            </ListItem>
                        </List>
                    </Box>

                    {/* Social Proof */}
                    <HStack justify="center" mt={6} spacing={4} color="gray.600">
                        <Icon as={FaUsers} />
                        <Text fontSize="sm">Join {totalSpots - spotsLeft} others who have already registered</Text>
                    </HStack>
                </ModalContent>
            </Modal>

            <FooterWithFourColumns />
        </Box>
    );
};

export default WebinarInvitePage;