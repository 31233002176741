import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack, useColorModeValue, WrapItem, Badge, Wrap, Grid,
    Spacer, Link, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Input,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { CheckCircleIcon, PhoneIcon, EmailIcon, ExternalLinkIcon, DownloadIcon, CalendarIcon, TimeIcon, InfoIcon, ChevronLeftIcon, ChevronRightIcon, SearchIcon, StarIcon, } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';

import CountdownTimer from '../components/CountdownTimer';
import SMSNotificationSignup from '../components/SMSNotificationSignup';

import { calculateBTCAmount } from '../utils/helpers';



const GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      currentQuantity
      goalQuantity
      status
    }
  }
`;


// GraphQL Queries and Mutations
const GET_BITCOIN_PRICE = gql`
  query GetBitcoinPrice {
    getBitcoinPrice
  }
`;

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!, $mongodbCampaignId: ID!, $quantity: Int!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId, mongodbCampaignId: $mongodbCampaignId, quantity: $quantity) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const SingleViewKangaroo = () => {
    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [pledgeCount, setPledgeCount] = useState(0);
    const [btcPrice, setBtcPrice] = useState(0);
    const [btcAmount, setBtcAmount] = useState(0);
    const [unitCount, setUnitCount] = useState(1); // New state for unit count
    const [priceLoaded, setPriceLoaded] = useState(false);

    // Constants
    const AUD_PRICE = 23.8; // 23.80 AUD per kilogram
    const GROUP_BUY_GOAL = 20; // KG
    const MAX_UNITS_PER_HEAD = 5;
    const CAMPAIGN_ID = "66de64d60feda6ecd54dd5fb";
    const PRODUCT_ID = "66de64890feda6ecd54dd5f2";



    // Add these style constants
    const styleConstants = {
        shadow: '0 8px 32px -8px rgba(0, 0, 0, 0.08), 0 4px 16px -4px rgba(0, 0, 0, 0.06)',
        cardBg: 'rgba(255, 255, 255, 0.95)',
        glassMorphism: {
            bg: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(12px)',
            border: '1px solid rgba(255, 255, 255, 0.18)'
        },
        borderRadius: '16px',
        transition: 'all 0.3s ease-in-out'
    };

    // Hooks
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    // GraphQL Mutations and Queries
    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    const { data: btcPriceData, loading: btcPriceLoading } = useQuery(GET_BITCOIN_PRICE, {
        pollInterval: 60000, // Poll every minute
    });

    // Effects

    // Fetch BTC price once on component mount
    useEffect(() => {
        if (btcPriceData) {
            setBtcPrice(btcPriceData.getBitcoinPrice);
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPriceData.getBitcoinPrice);
            setBtcAmount(newBtcAmount);
            setPriceLoaded(true);
        }
    }, [btcPriceData, unitCount]);

    // Update BTC amount when unit count changes
    useEffect(() => {
        if (priceLoaded) {
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [unitCount, btcPrice, priceLoaded]);

    // Start polling for invoice status when invoiceId is available
    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    // Handle invoice status changes
    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);

                toast({
                    title: "Payment Completed",
                    description: "Your pledge for the Kangaroo Meat Group Buy has been processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast, unitCount]);

    // todo, get the pledge count from the database
    const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
        variables: { id: CAMPAIGN_ID },
        pollInterval: 30000, // Poll every 30 seconds
    });


    useEffect(() => {
        if (campaignData && campaignData.getCampaign) {
            setPledgeCount(campaignData.getCampaign.currentQuantity);
        }
    }, [campaignData]);

    // Handler Functions

    // Handle pledge button click
    const handlePledge = async () => {
        if (!user) {
            toast({
                title: "Members Only",
                description: "Join our community for free! We're just getting started and offering free membership to early supporters.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
            navigate('/aboutus');
            return;
        }

        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before pledging your support.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const { data } = await createBTCPayInvoice({
                variables: {
                    amount: btcAmount,
                    currency: "BTC",
                    userId: user.id,
                    mongodbProductId: PRODUCT_ID,
                    mongodbCampaignId: CAMPAIGN_ID,
                    quantity: unitCount
                },
            });
            setInvoiceId(data.createBTCPayInvoice.id);
            setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
            setInvoiceStatus(data.createBTCPayInvoice.status);
            setIsModalOpen(true);
            refetchCampaign();
        } catch (error: any) {
            console.error('Error creating BTCPay invoice:', error);
            toast({
                title: "Error",
                description: `Failed to process pledge: ${error.message}`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };



    // Render loading state
    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    // Main component render
    return (
        <Box bg={"#EFEDDC"} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={4}>
                {/* Back Button */}
                <Button
                    leftIcon={<ChevronLeftIcon />}
                    variant="ghost"
                    mb={4}
                    color="gray.600"
                    onClick={() => navigate('/marketplace')}
                >
                    Back
                </Button>

                {/* Main Product Section */}
                <Grid templateColumns={["1fr", "1fr", "45% 55%"]} gap={8}>
                    {/* Left: Image Gallery Section */}
                    <Box>
                        <Box position="relative">
                      
                            {/* <Box
                                position="absolute"
                                top={4}
                                left={4}
                                zIndex={1}
                            >
                                <Image
                                    src="/icons/australian-made-logo.png"
                                    alt="Australian Made"
                                    width="60px"
                                    height="60px"
                                />
                            </Box> */}

                            {/* Main Product Image */}
                            <Image
                                src="/product_images/kangaroo.jpg"
                                alt="Premium Kangaroo Meat"
                                width="100%"
                                borderRadius="md"
                            />
                        </Box>
                    </Box>

                    {/* Right: Product Details */}
                    <VStack align="stretch" spacing={6} pr={[0, 0, 8]}>
                        {/* Product Title */}
                        <Heading
                            size="lg"
                            fontWeight="400"
                            color="#3a474e"
                        >
                            Premium Wild Kangaroo Meat
                        </Heading>

                        {/* Price Section */}
                        <HStack spacing={0} align="baseline">
                            <Text fontSize="xl">$</Text>
                            <Heading size="2xl">20</Heading>
                            <Text fontSize="xl">.00</Text>
                        </HStack>
                        <Text color="gray.600" fontSize="sm">$20.00/kg</Text>

                        {/* Rating Section */}
                        <HStack spacing={4}>
                            <Text fontSize="xl" fontWeight="bold">4.9</Text>
                            <HStack>
                                {[1, 2, 3, 4, 5].map((i) => (
                                    <StarIcon key={i} color="yellow.400" />
                                ))}
                            </HStack>
                            <Text color="gray.600">(127)</Text>
                        </HStack>

                        {/* Action Buttons */}
                        <VStack spacing={4} mt={4}>
                            {/* Quantity Controls */}
                            <Box
                                p={4}
                                border="1px"
                                borderColor="gray.200"
                                borderRadius="md"
                                width="full"
                            >
                                <HStack justify="space-between">
                                    <Button onClick={() => setUnitCount(Math.max(1, unitCount - 1))}>-</Button>
                                    <Input
                                        value={unitCount}
                                        textAlign="center"
                                        width="80px"
                                        onChange={(e) => setUnitCount(parseInt(e.target.value) || 1)}
                                    />
                                    <Button onClick={() => setUnitCount(Math.min(MAX_UNITS_PER_HEAD, unitCount + 1))}>+</Button>
                                </HStack>
                            </Box>

                            <Button
                                width="full"
                                colorScheme="green"
                                size="lg"
                                onClick={handlePledge}
                            >
                                Add to cart
                            </Button>
                        </VStack>
                    </VStack>
                </Grid>

                {/* Product Details Section */}
                <Box mt={8} bg="white" p={6} borderRadius="md">
                    <Heading size="md" mb={4}>Product Details</Heading>
                    <Text>
                        Our premium wild kangaroo meat is ethically sourced from the Australian outback.
                        Known for its lean, high-protein content and rich flavor, kangaroo meat is both
                        nutritious and environmentally sustainable. Each package is carefully processed
                        and flash-frozen to maintain optimal freshness.
                    </Text>
                    <Text mt={4} fontWeight="bold">100% Australian Wild Caught</Text>
                </Box>

                {/* Nutritional Information */}
                <Box mt={6} bg="white" p={6} borderRadius="md">
                    <Heading size="md" mb={4}>Nutrition Information</Heading>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Per 100g</Th>
                                    <Th isNumeric>Amount</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Energy</Td>
                                    <Td isNumeric>98kcal</Td>
                                </Tr>
                                <Tr>
                                    <Td>Protein</Td>
                                    <Td isNumeric>22g</Td>
                                </Tr>
                                <Tr>
                                    <Td>Fat, Total</Td>
                                    <Td isNumeric>1.3g</Td>
                                </Tr>
                                <Tr>
                                    <Td>Iron</Td>
                                    <Td isNumeric>3.2mg</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Reviews Section */}
                <Box mt={6} bg="white" p={6} borderRadius="md">
                    <Heading size="md" mb={4}>Ratings and Reviews</Heading>
                    <VStack align="stretch" spacing={4}>
                        {[
                            {
                                author: "MeatLover123",
                                rating: 5,
                                date: "2 days ago",
                                review: "Excellent quality meat. Very lean and perfect for my high-protein diet."
                            },
                            {
                                author: "ChefAussie",
                                rating: 5,
                                date: "1 week ago",
                                review: "The quality is outstanding. Makes amazing steaks when cooked properly."
                            }
                        ].map((review, index) => (
                            <Box key={index} p={4} borderBottom="1px" borderColor="gray.200">
                                <HStack mb={2}>
                                    <Text fontWeight="bold">{review.author}</Text>
                                    <Text color="gray.500">{review.date}</Text>
                                </HStack>
                                <HStack mb={2}>
                                    {[1, 2, 3, 4, 5].map((i) => (
                                        <StarIcon
                                            key={i}
                                            color={i <= review.rating ? "yellow.400" : "gray.200"}
                                        />
                                    ))}
                                </HStack>
                                <Text>{review.review}</Text>
                            </Box>
                        ))}
                    </VStack>
                </Box>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default SingleViewKangaroo;