import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    VStack,
    Select,
    NumberInput,
    NumberInputField,
    Checkbox,
    Textarea,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const SUBMIT_CONNECTION_REQUEST = gql`
  mutation SubmitConnectionRequest($input: ConnectionRequestInput!) {
    submitConnectionRequest(input: $input) {
      id
      status
    }
  }
`;

export const ConnectionRequestForm = () => {
    const toast = useToast();
    const [formData, setFormData] = useState({
        projectName: '',
        location: '',
        systemCapacity: 1000,
        exportType: 'full',
        inverterMake: '',
        inverterModel: '',
        inverterManufacturer: '',
        inverterCapacity: 1000,
        inverterQuantity: 10,
        compliantAS4777: true,
        cecApproved: true,
        centralProtection: true,
        passiveAntiIslanding: true,
        interTripping: true,
        voltVarEnabled: true,
        voltWattEnabled: true,
        regionalSetting: 'Australia C',
        communicationTechnology: '',
        technicalStudies: {
            voltageLevel: true,
            powerFlow: true,
            faultLevel: true,
            protectionGrading: true,
        },
        additionalNotes: '',
    });

    const [submitRequest, { loading }] = useMutation(SUBMIT_CONNECTION_REQUEST, {
        onCompleted: () => {
            toast({
                title: "Request submitted",
                description: "Your connection request has been successfully submitted.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        },
        onError: (error) => {
            toast({
                title: "Submission failed",
                description: error.message || "An unknown error occurred",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const handleInputChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            await submitRequest({
                variables: { input: formData },
            });
        } catch (error) {
            console.error('Submission error:', error);
        }
    };

    return (
        <>
            <NavbarWithCallToAction />
            <Container maxW="container.md" py={8}>
                <Heading as="h1" size="xl" mb={6}>
                    Connection Request Form
                </Heading>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="stretch">
                        <FormControl isRequired>
                            <FormLabel>Project Name</FormLabel>
                            <Input
                                name="projectName"
                                value={formData.projectName}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Location</FormLabel>
                            <Input
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>System Capacity (kW)</FormLabel>
                            <NumberInput min={0} max={10000} value={formData.systemCapacity}>
                                <NumberInputField
                                    name="systemCapacity"
                                    onChange={handleInputChange}
                                />
                            </NumberInput>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Export Type</FormLabel>
                            <Select
                                name="exportType"
                                value={formData.exportType}
                                onChange={handleInputChange}
                            >
                                <option value="full">Full Exporting</option>
                                <option value="partial">Partial Exporting</option>
                                <option value="none">Non-Exporting</option>
                            </Select>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Inverter Make</FormLabel>
                            <Input
                                name="inverterMake"
                                value={formData.inverterMake}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Inverter Model</FormLabel>
                            <Input
                                name="inverterModel"
                                value={formData.inverterModel}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Inverter Manufacturer</FormLabel>
                            <Input
                                name="inverterManufacturer"
                                value={formData.inverterManufacturer}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Inverter Capacity (kVA)</FormLabel>
                            <NumberInput min={0} max={10000} value={formData.inverterCapacity}>
                                <NumberInputField
                                    name="inverterCapacity"
                                    onChange={handleInputChange}
                                />
                            </NumberInput>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Number of Inverters</FormLabel>
                            <NumberInput min={1} max={100} value={formData.inverterQuantity}>
                                <NumberInputField
                                    name="inverterQuantity"
                                    onChange={handleInputChange}
                                />
                            </NumberInput>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                name="compliantAS4777"
                                isChecked={formData.compliantAS4777}
                                onChange={handleInputChange}
                            >
                                Compliant with AS/NZS 4777.2
                            </Checkbox>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                name="cecApproved"
                                isChecked={formData.cecApproved}
                                onChange={handleInputChange}
                            >
                                CEC Approved
                            </Checkbox>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                name="centralProtection"
                                isChecked={formData.centralProtection}
                                onChange={handleInputChange}
                            >
                                Central Protection System
                            </Checkbox>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                name="passiveAntiIslanding"
                                isChecked={formData.passiveAntiIslanding}
                                onChange={handleInputChange}
                            >
                                Passive Anti-Islanding Protection
                            </Checkbox>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                name="interTripping"
                                isChecked={formData.interTripping}
                                onChange={handleInputChange}
                            >
                                Inter-Tripping System
                            </Checkbox>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                name="voltVarEnabled"
                                isChecked={formData.voltVarEnabled}
                                onChange={handleInputChange}
                            >
                                Volt-Var Response Mode Enabled
                            </Checkbox>
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                name="voltWattEnabled"
                                isChecked={formData.voltWattEnabled}
                                onChange={handleInputChange}
                            >
                                Volt-Watt Response Mode Enabled
                            </Checkbox>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Regional Setting</FormLabel>
                            <Select
                                name="regionalSetting"
                                value={formData.regionalSetting}
                                onChange={handleInputChange}
                            >
                                <option value="Australia A">Australia A</option>
                                <option value="Australia B">Australia B</option>
                                <option value="Australia C">Australia C</option>
                            </Select>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Communication Technology</FormLabel>
                            <Input
                                name="communicationTechnology"
                                value={formData.communicationTechnology}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Technical Studies Required</FormLabel>
                            <Checkbox
                                name="technicalStudies.voltageLevel"
                                isChecked={formData.technicalStudies.voltageLevel}
                                onChange={handleInputChange}
                            >
                                Voltage Level Study
                            </Checkbox>
                            <Checkbox
                                name="technicalStudies.powerFlow"
                                isChecked={formData.technicalStudies.powerFlow}
                                onChange={handleInputChange}
                            >
                                Power Flow Study
                            </Checkbox>
                            <Checkbox
                                name="technicalStudies.faultLevel"
                                isChecked={formData.technicalStudies.faultLevel}
                                onChange={handleInputChange}
                            >
                                Fault Level Study
                            </Checkbox>
                            <Checkbox
                                name="technicalStudies.protectionGrading"
                                isChecked={formData.technicalStudies.protectionGrading}
                                onChange={handleInputChange}
                            >
                                Protection Grading Study
                            </Checkbox>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Additional Notes</FormLabel>
                            <Textarea
                                name="additionalNotes"
                                value={formData.additionalNotes}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            colorScheme="blue"
                            isLoading={loading}
                            loadingText="Submitting"
                        >
                            Submit Connection Request
                        </Button>
                    </VStack>
                </form>
            </Container>
            <FooterWithFourColumns />
        </>
    );
};

export default ConnectionRequestForm;