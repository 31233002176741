import React, { useState } from 'react';
import {
    Box, Button, Container, Heading, Text, VStack,
    FormControl, Input, useToast, Image, Stack,
    Icon, Badge, useColorModeValue, List, ListItem,
    SimpleGrid, Flex, Divider, Circle, Progress
} from '@chakra-ui/react';
import { CheckIcon, LockIcon, StarIcon, UnlockIcon } from '@chakra-ui/icons';
import {
    FaHeart, FaClock, FaVideo, FaClipboardCheck,
    FaUserFriends, FaChartLine,
    FaLock,
    FaCrown
} from 'react-icons/fa';
import { gql, useMutation } from '@apollo/client';

// TypeScript interfaces
interface FormData {
    name: string;
    email: string;
}

interface ChallengeDay {
    day: number;
    title: string;
    description: string;
    videoTitle: string;
    tool: string;
    toolFeatures: string[];
    task: string;
}

interface Statistic {
    number: string;
    label: string;
    icon: React.ComponentType;
}


// Enhanced TypeScript interfaces
interface UserStatus {
    isLoggedIn: boolean;
    hasCompletedChallenge: boolean;
    hasPurchasedCourse: boolean;
}

interface FormData {
    name: string;
    email: string;
}

interface ChallengeDay {
    day: number;
    title: string;
    description: string;
    videoTitle: string;
    tool: string;
    toolFeatures: string[];
    task: string;
    status: 'locked' | 'available' | 'completed';
}

// User status simulation
const getUserStatus = (): UserStatus => {
    console.log('📊 Checking user status');
    // This would normally come from your auth system
    return {
        isLoggedIn: false,
        hasCompletedChallenge: false,
        hasPurchasedCourse: false
    };
};

// GraphQL mutation
const CREATE_CHALLENGE_LEAD = gql`
    mutation CreateChallengeLead($email: String!, $name: String!) {
        createLead(email: $email, name: $name) {
            id
            email
            name
        }
    }
`;

const LeadMagnetChallenge: React.FC = () => {
    console.log('🚀 Challenge Lead Magnet component mounted');

    const [userStatus, setUserStatus] = useState<UserStatus>(getUserStatus());
    const [formData, setFormData] = useState<FormData>({ name: '', email: '' });
    const toast = useToast();

    // Theme colors
    const bgMain = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    // const accentColor = useColorModeValue('blue.500', 'blue.300');
    const bgColor = useColorModeValue('purple.50', 'purple.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const accentColor = useColorModeValue('purple.500', 'purple.300');
    const textColor = useColorModeValue('gray.600', 'gray.200');
    const borderColor = useColorModeValue('purple.100', 'purple.700');


    // Challenge day data with status
    const getChallengeStatus = (day: number): 'locked' | 'available' | 'completed' => {
        if (!userStatus.isLoggedIn) return 'locked';
        if (userStatus.hasCompletedChallenge) return 'completed';
        return day === 1 ? 'available' : 'locked';
    };

    const challengeDays: ChallengeDay[] = [
        {
            day: 1,
            title: "The Connection Reset",
            description: "Master the science of small moments and transform daily interactions",
            videoTitle: "The Science of Small Moments (15 mins)",
            tool: "Connection Opportunity Detector™",
            toolFeatures: [
                "Morning moment checklist",
                "Digital connection points",
                "Evening reconnection guide",
                "Response tracking sheet"
            ],
            task: "Track and respond to 3 specific connection opportunities",
            status: getChallengeStatus(1)
        },
        {
            day: 2,
            title: "The Appreciation Accelerator",
            description: "Learn the neural impact of genuine gratitude and boost intimacy",
            videoTitle: "The 60-Second Intimacy Boost (15 mins)",
            tool: "Appreciation Impact Matrix™",
            toolFeatures: [
                "Moment capture guide",
                "Delivery timing optimizer",
                "Response tracking system",
                "Pattern recognition grid"
            ],
            task: "Deliver 3 structured appreciations",
            status: getChallengeStatus(2)
        },
        {
            day: 3,
            title: "The Stress-Connection Flip",
            description: "Transform tension into closeness with proven techniques",
            videoTitle: "From Tension to Closeness (15 mins)",
            tool: "Tension Transformation Map™",
            toolFeatures: [
                "Stress signal detector",
                "Response optimizer",
                "Recovery accelerator",
                "Connection builder"
            ],
            task: "Apply flip technique to one tension moment",
            status: getChallengeStatus(3)
        }
    ];

    const renderChallengeStatus = (status: 'locked' | 'available' | 'completed') => {
        switch (status) {
            case 'locked':
                return (
                    <Button
                        leftIcon={<FaLock />}
                        colorScheme="gray"
                        isDisabled
                        w="full"
                    >
                        Unlock This Day
                    </Button>
                );
            case 'available':
                return (
                    <Button
                        leftIcon={<UnlockIcon />}
                        colorScheme="blue"
                        w="full"
                    >
                        Start Today's Challenge
                    </Button>
                );
            case 'completed':
                return (
                    <Button
                        leftIcon={<StarIcon />}
                        colorScheme="green"
                        w="full"
                    >
                        Review Completed Day
                    </Button>
                );
        }
    };

    // Statistics data
    const statistics: Statistic[] = [
        { number: "72hrs", label: "To Transform", icon: FaClock },
        { number: "15min", label: "Daily Videos", icon: FaVideo },
        { number: "89%", label: "Success Rate", icon: FaChartLine }
    ];

    const [createLead, { loading }] = useMutation(CREATE_CHALLENGE_LEAD, {
        onCompleted: () => {
            console.log('✅ Challenge lead captured successfully');
            toast({
                title: "Welcome to the Challenge!",
                description: "Check your email for Day 1 access",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        },
        onError: (error) => {
            console.error('❌ Challenge lead capture error:', error);
            toast({
                title: "Connection Error",
                description: "Please try again in a moment",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('📝 Processing challenge registration:', formData);

        if (!formData.name || !formData.email) {
            toast({
                title: "Missing Information",
                description: "Please provide both name and email",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        await createLead({ variables: formData });
    };

    return (
        <Box>
            {/* Hero Section */}
            <Box
                bg="blue.600"
                color="white"
                py={20}
                position="relative"
                overflow="hidden"
            >
                <Container maxW="container.xl">
                    <SimpleGrid columns={{ base: 1, lg: 2 }} gap={16} alignItems="center">
                        <Box>
                            <Badge
                                colorScheme="orange"
                                fontSize="sm"
                                mb={4}
                                px={3}
                                py={1}
                                borderRadius="full"
                            >
                                FREE 3-DAY CHALLENGE
                            </Badge>
                            <Heading
                                as="h1"
                                fontSize={{ base: "4xl", md: "5xl" }}
                                mb={6}
                            >
                                Transform Your Relationship in Just 72 Hours
                            </Heading>
                            <Text fontSize="xl" mb={8}>
                                Join our science-based challenge and master three powerful techniques
                                that will revolutionize your relationship dynamics forever.
                            </Text>
                            <Progress value={80} colorScheme="orange" mb={4} />
                            <Text fontSize="sm" mb={8}>
                                80% of spots filled for this month
                            </Text>
                            <Button
                                size="lg"
                                bg="white"
                                color="blue.600"
                                _hover={{ bg: 'gray.100' }}
                                onClick={() => window.scrollTo({ top: 800, behavior: 'smooth' })}
                            >
                                Join The Challenge Now
                            </Button>
                        </Box>
                        <Box>
                            <Image
                                src="/course_images/relationships/3daychallenge.png"
                                alt="Happy Couple"
                                borderRadius="xl"
                                shadow="2xl"
                            />
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Statistics Section */}
            <Box py={16} bg={cardBg}>
                <Container maxW="container.xl">
                    <SimpleGrid columns={{ base: 1, md: 3 }} gap={8}>
                        {statistics.map((stat, idx) => (
                            <VStack
                                key={idx}
                                p={8}
                                bg={bgMain}
                                borderRadius="xl"
                                spacing={4}
                                shadow="lg"
                            >
                                <Circle size="60px" bg="blue.500" color="white">
                                    <Icon as={stat.icon} w={6} h={6} />
                                </Circle>
                                <Text fontSize="4xl" fontWeight="bold" color={accentColor}>
                                    {stat.number}
                                </Text>
                                <Text fontSize="lg">
                                    {stat.label}
                                </Text>
                            </VStack>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>



            {/* Challenges Section */}            
            <Box py={20} bg={bgMain}>
                <Container maxW="container.xl">
                    <VStack spacing={8} mb={16}>
                        <Heading textAlign="center">Your 3-Day Journey</Heading>
                        {!userStatus.isLoggedIn && (
                            <Badge
                                colorScheme="orange"
                                p={2}
                                borderRadius="md"
                                fontSize="md"
                            >
                                Sign up to unlock your free challenge access
                            </Badge>
                        )}
                        {userStatus.hasCompletedChallenge && (
                            <Badge
                                colorScheme="green"
                                p={2}
                                borderRadius="md"
                                fontSize="md"
                            >
                                🎉 Challenge Completed! Unlock the full course below
                            </Badge>
                        )}
                    </VStack>

                    <SimpleGrid columns={{ base: 1, md: 3 }} gap={8}>
                        {challengeDays.map((day, idx) => (
                            <Box
                                key={idx}
                                p={8}
                                bg={cardBg}
                                borderRadius="xl"
                                shadow="lg"
                                position="relative"
                                opacity={day.status === 'locked' ? 0.8 : 1}
                            >
                                {day.status === 'locked' && (
                                    <Box
                                        position="absolute"
                                        top={4}
                                        right={4}
                                        p={2}
                                        borderRadius="full"
                                        bg="gray.500"
                                        color="white"
                                    >
                                        <FaLock />
                                    </Box>
                                )}
                                {day.status === 'completed' && (
                                    <Box
                                        position="absolute"
                                        top={4}
                                        right={4}
                                        p={2}
                                        borderRadius="full"
                                        bg="green.500"
                                        color="white"
                                    >
                                        <CheckIcon />
                                    </Box>
                                )}
                                <VStack align="start" spacing={6}>
                                    <Badge
                                        colorScheme={day.status === 'completed' ? 'green' : 'blue'}
                                        fontSize="md"
                                        px={3}
                                        py={1}
                                    >
                                        Day {day.day}
                                    </Badge>
                                    <Heading size="md">{day.title}</Heading>
                                    <Text>{day.description}</Text>
                                    <Box w="full">
                                        <Text fontWeight="bold" mb={2}>
                                            <Icon as={FaVideo} mr={2} />
                                            {day.videoTitle}
                                        </Text>
                                        <Text fontWeight="bold" mb={2}>
                                            <Icon as={FaClipboardCheck} mr={2} />
                                            {day.tool}
                                        </Text>
                                        <List spacing={2}>
                                            {day.toolFeatures.map((feature, fidx) => (
                                                <ListItem key={fidx} display="flex">
                                                    <Icon as={CheckIcon} mr={2} color="green.500" />
                                                    <Text fontSize="sm">{feature}</Text>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                    <Box w="full">
                                        {renderChallengeStatus(day.status)}
                                    </Box>
                                </VStack>
                            </Box>
                        ))}
                    </SimpleGrid>

                    {userStatus.hasCompletedChallenge && !userStatus.hasPurchasedCourse && (
                        <Box
                            mt={16}
                            p={8}
                            bg="blue.600"
                            color="white"
                            borderRadius="xl"
                            textAlign="center"
                        >
                            <VStack spacing={4}>
                                <Icon as={FaCrown} w={8} h={8} />
                                <Heading size="lg">Ready for the Next Step?</Heading>
                                <Text>Unlock our complete Relationship Mastery Course</Text>
                                <Button
                                    size="lg"
                                    colorScheme="white"
                                    variant="outline"
                                    _hover={{ bg: 'whiteAlpha.200' }}
                                >
                                    Learn More About the Full Course
                                </Button>
                            </VStack>
                        </Box>
                    )}
                </Container>
            </Box>

            {/* Registration Section */}
            <Box
                py={20}
                bg="blue.600"
                color="white"
                position="relative"
            >
                <Container maxW="container.lg">
                    <VStack spacing={8} align="center" maxW="600px" mx="auto">
                        <Badge
                            colorScheme="orange"
                            px={3}
                            py={1}
                            borderRadius="full"
                        >
                            LIMITED SPOTS AVAILABLE
                        </Badge>
                        <Heading textAlign="center">
                            Join the 3-Day Relationship Revival Challenge
                        </Heading>
                        <Text fontSize="lg" textAlign="center">
                            Transform your relationship with just 15 minutes per day.
                            Get instant access to our proven system plus bonus materials.
                        </Text>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <VStack spacing={4} w="full">
                                <FormControl isRequired>
                                    <Input
                                        bg="whiteAlpha.200"
                                        border="none"
                                        placeholder="Your Name"
                                        size="lg"
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl isRequired>
                                    <Input
                                        bg="whiteAlpha.200"
                                        border="none"
                                        placeholder="Your Email"
                                        size="lg"
                                        type="email"
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    size="lg"
                                    bg="white"
                                    color="blue.600"
                                    w="full"
                                    _hover={{ bg: 'gray.100' }}
                                    isLoading={loading}
                                    loadingText="Securing Your Spot..."
                                >
                                    Start Your Transformation Now
                                </Button>
                            </VStack>
                        </form>
                        <Text fontSize="sm" textAlign="center">
                            <Icon as={LockIcon} mr={2} />
                            Your information is secure and will never be shared
                        </Text>
                    </VStack>
                </Container>
            </Box>
        </Box>
    );
};

export default LeadMagnetChallenge;