import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Heading,
    Text,
    Spinner,
    Stat,
    StatGroup,
    StatLabel,
    StatNumber,
    StatHelpText,
    Container,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// Define the type for our registration data
interface RelationshipCourseRegistration {
    id: string;
    fName: string;
    lName: string;
    phoneNumber: string;
    email: string;
    createdAt: string;
    updatedAt: string;
}

// GraphQL query to fetch all registrations
const GET_ALL_REGISTRATIONS = gql`
    query GetAllRegistrations {
        getAllRelationshipsCourseRegistrations {
            id
            fName
            lName
            phoneNumber
            email
            createdAt
            updatedAt
        }
    }
`;



const formatDate = (dateString: string) => {
    try {
        // First try parsing as ISO string
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            // If that fails, try parsing as timestamp
            const timestamp = parseInt(dateString);
            const timestampDate = new Date(timestamp);
            return isNaN(timestampDate.getTime()) ? 'Invalid Date' : timestampDate.toLocaleString();
        }
        return date.toLocaleString();
    } catch (error) {
        console.error('Error formatting date:', dateString, error);
        return 'Invalid Date';
    }
};


const AllRelationshipCourseRegistrations = () => {
    console.log('🎨 Rendering Relationship Course Registrations Page');

    const { user } = useAuth();
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(GET_ALL_REGISTRATIONS);

    // Color scheme
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    // useEffect(() => {
    //     if (!user || !user.permissions?.includes('admin')) {
    //         navigate('/');
    //     }
    // }, [user, navigate]);

    // Calculate summary statistics
    const summaryStats = React.useMemo(() => {
        if (!data?.getAllRelationshipsCourseRegistrations) return {
            totalRegistrations: 0,
            last24Hours: 0,
            last7Days: 0,
            last30Days: 0
        };

        const registrations = data.getAllRelationshipsCourseRegistrations;
        const now = new Date();
        const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

        return {
            totalRegistrations: registrations.length,
            // last24Hours: registrations.filter(reg => new Date(reg.createdAt) >= oneDayAgo).length,
            // last7Days: registrations.filter(reg => new Date(reg.createdAt) >= sevenDaysAgo).length,
            // last30Days: registrations.filter(reg => new Date(reg.createdAt) >= thirtyDaysAgo).length
        };
    }, [data]);

    // if (loading) return (
    //     <Box>
    //         <NavbarWithCallToAction />
    //         <Box maxWidth="1200px" margin="auto" padding={8}>
    //             <Spinner />
    //         </Box>
    //         <FooterWithFourColumns />
    //     </Box>
    // );

    // if (error) return (
    //     <Box>
    //         <NavbarWithCallToAction />
    //         <Box maxWidth="1200px" margin="auto" padding={8}>
    //             <Text>Error: {error.message}</Text>
    //         </Box>
    //         <FooterWithFourColumns />
    //     </Box>
    // );

    return (
        <Box bg={bgColor}>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={10}>
                <VStack spacing={10} align="stretch">
                    {/* ... stats box ... */}

                    <Box
                        bg={cardBgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={6}>
                            All Registrations
                        </Heading>
                        <TableContainer>
                            <Table variant="simple">
                                <TableCaption>All relationship course registrations</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Registration Date</Th>
                                        <Th>Name</Th>
                                        <Th>Email</Th>
                                        <Th>Phone</Th>
                                        <Th>Last Updated</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data?.getAllRelationshipsCourseRegistrations?.map((registration: RelationshipCourseRegistration) => {
                                        // Debug log to see what date values we're receiving
                                        console.log('Date values:', {
                                            createdAt: registration.createdAt,
                                            updatedAt: registration.updatedAt
                                        });

                                        return (
                                            <Tr key={registration.id}>
                                                <Td>{formatDate(registration.createdAt)}</Td>
                                                <Td>{`${registration.fName} ${registration.lName}`}</Td>
                                                <Td>{registration.email}</Td>
                                                <Td>{registration.phoneNumber}</Td>
                                                <Td>{formatDate(registration.updatedAt)}</Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </VStack>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default AllRelationshipCourseRegistrations;