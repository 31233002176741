// pages/Login.tsx

import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    useToast,
    Flex,
    Text,
    Container,
} from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { useColorModeValue } from '@chakra-ui/react';

export const Login = () => {
    const [email, setEmail] = useState("");
    const { login, isLoading } = useAuth();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    // Updated color values to use green
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const highlightBgColor = useColorModeValue('green.50', 'green.900');
    const highlightBorderColor = useColorModeValue('green.200', 'green.700');

    useEffect(() => {
        const pendingInviteCode = localStorage.getItem('pendingInviteCode');
        if (pendingInviteCode) {
            toast({
                title: "Invite Code Detected",
                description: "Log in to use the invite code.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [toast]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await login(email);
            if (typeof result === 'string' && result === 'incomplete_profile') {
                toast({
                    title: "Profile Incomplete",
                    description: "Please complete your profile.",
                    status: "info",
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/profiledetails');
            } else {
                toast({
                    title: "Login successful",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                const from = (location.state as any)?.from || '/';
                navigate(from);
            }
        } catch (error) {
            console.error('Login error:', error);
            toast({
                title: "Login failed",
                description: error instanceof Error ? error.message : "Please check your email and try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex direction="column" minHeight="100vh" bg={"#EFEDDC"}>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" flex="1" display="flex" justifyContent="center" alignItems="center">
                <Box
                    borderWidth={2}
                    borderRadius="lg"
                    p={8}
                    bg={highlightBgColor}
                    borderColor={highlightBorderColor}
                    boxShadow="xl"
                    transition="all 0.3s"
                    _hover={{ transform: 'scale(1.02)' }}
                    width="full"
                    maxWidth="md"
                >
                    <VStack spacing={6} width="full">
                        <Heading as="h1" size="xl" textAlign="center">
                            Login to Your Account
                        </Heading>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <FormControl id="email" isRequired>
                                <FormLabel>Email address</FormLabel>
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    bg={bgColor}
                                    borderColor={borderColor}
                                    _hover={{ borderColor: 'green.300' }}
                                    _focus={{ borderColor: 'green.500', boxShadow: '0 0 0 1px green.500' }}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                colorScheme="green"
                                mt={6}
                                width="full"
                                isLoading={isLoading}
                                loadingText="Logging in"
                                size="lg"
                                _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                transition="all 0.2s"
                            >
                                Login
                            </Button>
                        </form>
                        <Text fontSize="sm" textAlign="center">
                            Don't have an account? Login with your email to create one.
                        </Text>
                    </VStack>
                </Box>
            </Container>
            <Box position="fixed" bottom={4} left={4} fontSize="xs" color="gray.500">
                <Text>InviteCode: <Text as="span" fontWeight="bold">{localStorage.getItem('pendingInviteCode') || 'None'}</Text></Text>
                <Text>Starting Product URL: <Text as="span" fontWeight="bold">{localStorage.getItem('startingProductURL') || 'None'}</Text></Text>
            </Box>
            <FooterWithFourColumns />
        </Flex>
    );
};

export default Login;