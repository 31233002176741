import React, { useEffect } from 'react';
import {
    Box, Container, Heading, Text, VStack, Button, Image,
    useColorModeValue, SimpleGrid, Link, List, ListItem, ListIcon
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const OurRekoModel = () => {
    const bgColor = useColorModeValue('green.50', 'green.900');
    const cardBgColor = useColorModeValue('white', 'green.800');
    const textColor = useColorModeValue('gray.800', 'white');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const rekoCharacteristics = [
        "Online Ordering through closed Facebook groups or online shops",
        "Direct sales from producers to consumers, eliminating middlemen",
        "Focus on local and regional producers",
        "Weekly or bi-weekly pickups at designated locations",
        "Wide variety of products including fresh produce, baked goods, meat, dairy, and eggs"
    ];

    const rekoBenefits = [
        "Fair prices for producers",
        "Increased transparency in food sourcing",
        "Reduced carbon footprint",
        "Community building and local economic development",
        "Support for sustainable and responsible consumption"
    ];

    return (
        <Box bg={"#EFEDDC"} minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box textAlign="center">
                        <Heading as="h1" size="2xl" mb={4}>
                            Our Reko Model
                        </Heading>
                        <Text fontSize="xl" mb={6}>
                            Discover how Pledge Buy is revolutionizing food distribution using the Reko Ring model
                        </Text>
                    </Box>

                    {/* New Image Section */}
                    <Box textAlign="center">
                        <Image
                            src="/reko_green.png"
                            alt="Reko Green Logo"
                            maxWidth="400px"
                            margin="auto"
                            borderRadius="lg"
                            boxShadow="lg"
                        />
                    </Box>
                    {/* What is Reko Ring? */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>
                            What is a Reko Ring?
                        </Heading>
                        <Text fontSize="lg" mb={4}>
                            A REKO ring is a community-based, online platform that enables local food producers to sell their products directly to consumers, bypassing intermediaries and traditional distribution channels. This innovative model originated in Finland and has since spread to over 14 countries, with more than 600 rings and 2 million members worldwide.
                        </Text>
                        <Text fontSize="lg" mb={6}>
                            Fun fact: "REKO" stands for "Rejäl Konsumtion" in Swedish, which means "Fair Consumption". In Finnish, it's short for "Reilua Kuluttamista", which roughly translates to "fair dinkum" in Australian slang. So you could say REKO is the fair dinkum way to buy your tucker!
                        </Text>
                        <Text fontSize="lg" fontStyle="italic" mb={6}>
                            Why did the chicken join a REKO ring? Because it wanted to make a fair cluck in the local economy! 🐔
                        </Text>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                                <Heading as="h3" size="lg" mb={4}>
                                    Key Characteristics
                                </Heading>
                                <List spacing={3}>
                                    {rekoCharacteristics.map((item, index) => (
                                        <ListItem key={index}>
                                            <ListIcon as={CheckCircleIcon} color="green.500" />
                                            {item}
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                                <Heading as="h3" size="lg" mb={4}>
                                    Benefits
                                </Heading>
                                <List spacing={3}>
                                    {rekoBenefits.map((item, index) => (
                                        <ListItem key={index}>
                                            <ListIcon as={CheckCircleIcon} color="green.500" />
                                            {item}
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </SimpleGrid>
                    </Box>

                    {/* New Section: Why We Use the Reko Model */}
                    <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                        <Heading as="h2" size="xl" mb={4}>
                            Why We Use the Reko Model
                        </Heading>
                        <Text fontSize="lg" mb={6}>
                            At Pledge Buy, we've adopted the Reko model to distribute food outside of corporate profit agendas. Our goal is to create a sustainable, community-driven food system that benefits both producers and consumers while challenging the dominance of large supermarket chains like Coles and Woolworths.
                        </Text>
                        <Text fontSize="lg" mb={6}>
                            By implementing the Reko model, we aim to:
                        </Text>
                        <List spacing={3} mb={6}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Shift market share from large corporations back to local producers
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Keep more money within our local communities
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Reduce carbon footprint by shortening supply chains
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Support local jobs and businesses
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Increase transparency in food sourcing
                            </ListItem>
                        </List>
                        <Text fontSize="lg" mb={6}>
                            We track our progress and impact through our Farm Sales Impact Dashboard, which shows how we're making a difference in our local food system.
                        </Text>
                        <Button as={Link} href="/farm-sales-dashboard" colorScheme="green" size="lg">
                            View Our Impact Dashboard
                        </Button>
                    </Box>

                    {/* Our Approach */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>
                            Pledge Buy's Approach
                        </Heading>
                        <Text fontSize="lg" mb={6}>
                            At Pledge Buy, we've adopted the Reko model to distribute food outside of corporate profit agendas. Our goal is to create a sustainable, community-driven food system that benefits both producers and consumers. Here's how we're implementing and improving upon the Reko model:
                        </Text>
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                                <Heading as="h3" size="lg" mb={4}>
                                    Balanced Sustainability
                                </Heading>
                                <Text>
                                    We prioritize economic, social, and environmental sustainability equally, ensuring a well-rounded approach to food distribution.
                                </Text>
                            </Box>
                            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                                <Heading as="h3" size="lg" mb={4}>
                                    Enhanced Interactions
                                </Heading>
                                <Text>
                                    We create opportunities for deeper producer-consumer interactions, fostering community connections and education.
                                </Text>
                            </Box>
                            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                                <Heading as="h3" size="lg" mb={4}>
                                    Coordinated Network
                                </Heading>
                                <Text>
                                    We maintain strong coordination between rings, sharing best practices and creating a cohesive network.
                                </Text>
                            </Box>
                        </SimpleGrid>
                    </Box>
                    {/* Call to Action */}
                    <Box textAlign="center" mt={12}>
                        <Heading as="h2" size="xl" mb={4}>
                            Join Our Reko Network
                        </Heading>
                        <Text fontSize="lg" mb={6}>
                            Whether you're a producer looking to sell your products or a consumer interested in supporting local agriculture, we'd love to have you join our Reko network!
                        </Text>
                        <Button
                            as={Link}
                            href="/producers"  // You can change this to the appropriate producer page
                            colorScheme="green"
                            size="lg"
                            mr={4}
                        >
                            I'm a Producer
                        </Button>
                        <Button
                            as={Link}
                            href="/marketplace"
                            colorScheme="blue"
                            size="lg"
                        >
                            I'm a Consumer
                        </Button>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default OurRekoModel;