import React, { useState } from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Image,
    Flex,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Link as ChakraLink,
    Center
} from "@chakra-ui/react";
import { Main } from "../components/chakra/LayoutWithStickyNavbar/Main";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { useAuth } from "../contexts/AuthContext";
import { useInviteCode } from '../hooks/useInviteCode';
import { ShowcaseGrid } from "../components/chakra/ShowcaseGrid/ShowcaseGrid";

export const Home = () => {
    useInviteCode();
    const { user, logout } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [phoneNumber, setPhoneNumber] = useState("");

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log("Submitted phone number:", phoneNumber);
        onClose();
    };

    return (
        <>
            <Flex direction="column" minHeight="100vh" bg={"#EFEDDC"}>
                <NavbarWithCallToAction />

                {/* Banner section */}
                <Box width="100%" mt={10} px={4}> {/* Added top margin and horizontal padding */}
                    <Center>
                        <Image
                            src="/headder.png"
                            alt="Life Essentials Club Banner"
                            maxWidth={{
                                base: "90%", // On mobile devices
                                md: "50%"    // On medium devices and up
                            }}
                            height="auto"
                            objectFit="contain"
                        />
                    </Center>
                </Box>


                <Container maxW="container.xl" centerContent bg={"#EFEDDC"}>
                    <Container maxW="100%" bg={"#EFEDDC"} id="marketplace">
                        <ShowcaseGrid />
                    </Container>
                    <VStack spacing={12} align="center"> {/* Increased spacing */}


                        <Heading
                            as="h1"
                            size="2xl"
                            textAlign="center"
                            fontFamily="'Times New Roman', Times, serif"
                            letterSpacing="2px"
                            fontWeight="400"
                            color="#2C5282"
                            textTransform="capitalize"
                            sx={{
                                position: "relative",
                                margin: "30px 0",
                                "&::before, &::after": {
                                    content: '""',
                                    position: "absolute",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    height: "25px",
                                    width: "200px",
                                    backgroundImage: "url('/decorative-line.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    opacity: 0.6
                                },
                                "&::before": {
                                    top: "-30px"
                                },
                                "&::after": {
                                    bottom: "-30px"
                                }
                            }}
                        >
                            Welcome to{" "}
                            <Box
                                as="span"
                                color="#1A365D"
                                fontStyle="italic"
                            >
                                Life Essentials Club
                            </Box>
                        </Heading>

                        {/* Styled subheading */}
                        <Text
                            fontSize="2xl"
                            textAlign="center"
                            fontFamily="'Times New Roman', Times, serif"
                            color="#4A5568"
                            fontStyle="italic"
                            letterSpacing="1px"
                            lineHeight="1.8"
                            maxW="700px"
                            sx={{
                                position: "relative",
                                "&::after": {
                                    content: '""',
                                    display: "block",
                                    width: "100px",
                                    height: "2px",
                                    background: "#CBD5E0",
                                    margin: "20px auto",
                                }
                            }}
                        >
                            Discover premium essentials for a better life
                        </Text>

                        {/* Styled button container */}
                        <Flex gap={4} mb={10}>
                            <ChakraLink href="/marketplace">
                                <Button colorScheme="blue" size="lg">
                                    Buy Now
                                </Button>
                            </ChakraLink>
                            <Button colorScheme="green" size="lg" onClick={onOpen}>
                                Visit Pledge Buys
                            </Button>
                        </Flex>

                        {/* <Flex
                            gap={6}
                            mt={4}
                            sx={{
                                position: "relative",
                                padding: "20px 0",
                            }}
                        >
                            <ChakraLink href="/marketplace">
                                <Button
                                    size="lg"
                                    px={8}
                                    py={6}
                                    bg="#2C5282"
                                    color="white"
                                    _hover={{ bg: "#1A365D" }}
                                    fontFamily="'Times New Roman', Times, serif"
                                    letterSpacing="1px"
                                    fontSize="lg"
                                    transition="all 0.3s ease"
                                    sx={{
                                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                        "&:hover": {
                                            transform: "translateY(-2px)",
                                            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                                        }
                                    }}
                                >
                                    Buy Now
                                </Button>
                            </ChakraLink>

                            <Button
                                size="lg"
                                px={8}
                                py={6}
                                bg="#38A169"
                                color="white"
                                _hover={{ bg: "#276749" }}
                                onClick={onOpen}
                                fontFamily="'Times New Roman', Times, serif"
                                letterSpacing="1px"
                                fontSize="lg"
                                transition="all 0.3s ease"
                                sx={{
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                    "&:hover": {
                                        transform: "translateY(-2px)",
                                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                                    }
                                }}
                            >
                                Visit Pledge Buys
                            </Button>
                        </Flex> */}
                    </VStack>
                </Container>


                <Flex direction="column" flex="1" >
                    <Main />
                </Flex>
                <FooterWithFourColumns />
            </Flex>

            {/* Modal for Pledge Buys subscription */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Get notified about Pledge Buys</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <FormControl>
                                <FormLabel>Mobile Phone Number</FormLabel>
                                <Input
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Enter your mobile number"
                                />
                            </FormControl>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Subscribe
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Home;