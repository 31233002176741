import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack, useColorModeValue, WrapItem, Badge, Wrap, Grid,
    Spacer, Link, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark,
} from '@chakra-ui/react';
import { CheckCircleIcon, PhoneIcon, EmailIcon, ExternalLinkIcon, DownloadIcon, CalendarIcon, TimeIcon, InfoIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';
import CountdownTimer from '../components/CountdownTimer';
import SMSNotificationSignup from '../components/SMSNotificationSignup';
import { calculateBTCAmount } from '../utils/helpers';

const GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      currentQuantity
      goalQuantity
      status
    }
  }
`;

const GET_BITCOIN_PRICE = gql`
  query GetBitcoinPrice {
    getBitcoinPrice
  }
`;

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!, $mongodbCampaignId: ID!, $quantity: Int!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId, mongodbCampaignId: $mongodbCampaignId, quantity: $quantity) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const TrialKangarooGroupBuy = () => {
    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [pledgeCount, setPledgeCount] = useState(0);
    const [btcPrice, setBtcPrice] = useState(0);
    const [btcAmount, setBtcAmount] = useState(0);
    const [unitCount, setUnitCount] = useState(1);
    const [priceLoaded, setPriceLoaded] = useState(false);

    // Constants
    const AUD_PRICE = 20; // $20 AUD per unit
    const GROUP_BUY_GOAL = 25;
    const MAX_UNITS_PER_HEAD = 5;
    const CAMPAIGN_ID = "66d7d48cc93e14eb3d7b922d";
    const PRODUCT_ID = "66d7ac5e8934ce2e00173f83";

    // Chakra UI color mode values
    const bgColor = useColorModeValue('orange.50', 'orange.900');
    const cardBgColor = useColorModeValue('white', 'orange.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('orange.500', 'orange.300');

    // Hooks
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    // GraphQL Mutations and Queries
    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    const { data: btcPriceData, loading: btcPriceLoading } = useQuery(GET_BITCOIN_PRICE, {
        pollInterval: 60000,
    });

    const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
        variables: { id: CAMPAIGN_ID },
        pollInterval: 30000,
    });

    // Effects
    useEffect(() => {
        if (btcPriceData) {
            setBtcPrice(btcPriceData.getBitcoinPrice);
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPriceData.getBitcoinPrice);
            setBtcAmount(newBtcAmount);
            setPriceLoaded(true);
        }
    }, [btcPriceData, unitCount]);

    useEffect(() => {
        if (priceLoaded) {
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [unitCount, btcPrice, priceLoaded]);

    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your pledge for the Kangaroo Meat Group Buy has been processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast]);

    useEffect(() => {
        if (campaignData && campaignData.getCampaign) {
            setPledgeCount(campaignData.getCampaign.currentQuantity);
        }
    }, [campaignData]);

    // Handler Functions
    const handlePledge = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before pledging your support.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createBTCPayInvoice({
                    variables: {
                        amount: btcAmount,
                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: PRODUCT_ID,
                        mongodbCampaignId: CAMPAIGN_ID,
                        quantity: unitCount
                    },
                });
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
                refetchCampaign();
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process pledge: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            navigate('/login', { state: { from: location } });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Pledge Confirmed",
                description: "Thank you for your pledge to the Kangaroo Meat Group Buy!",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Pledge Pending",
                description: "Complete your payment to finalize your pledge.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box borderRadius="xl" overflow="hidden" boxShadow="2xl" bg={cardBgColor} position="relative">
                        <Image
                            src="/product_images/kangaroo.jpg"
                            alt="Ethically Sourced Wild Kangaroo Meat"
                            width="100%"
                            height="300px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                Ethically Sourced Wild Kangaroo Meat
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Trial Round 1: Building Food Resilience in South East Queensland
                            </Text>
                        </Box>
                    </Box>

                    {/* Introduction Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Text fontSize="xl" lineHeight="tall">
                            Join our innovative trial to build food resilience in our community. We're starting with premium wild kangaroo meat, and plan to expand to more high-quality, ethically sourced products as we grow.
                        </Text>
                    </Box>

                    {/* Product Details Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>Product Details</Heading>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            <Box>
                                <Image
                                    src="/product_images/kangaroo_meat_package.jpg"
                                    alt="Frozen Kangaroo Meat Package"
                                    borderRadius="md"
                                />
                            </Box>
                            <VStack align="start" spacing={4}>
                                <Text><strong>Type:</strong> Frozen Wild Kangaroo Meat</Text>
                                <Text><strong>Quantity:</strong> 1 kg per unit</Text>
                                <Text><strong>Price:</strong> ${AUD_PRICE} AUD per kg</Text>
                                <Text><strong>Pickup Locations:</strong> Noosa and Woolloongabba</Text>
                                <Text>Ethically sourced, lean, and healthy protein option.</Text>
                            </VStack>
                        </Grid>
                    </Box>

                    {/* Group Buy Progress */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>Group Buy Progress</Heading>
                        <Text mb={4}><strong>Current Progress:</strong> {pledgeCount} out of {campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL} pledges</Text>
                        <Progress
                            value={(pledgeCount / (campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL)) * 100}
                            colorScheme="orange"
                            size="lg"
                            mb={4}
                        />
                        <Text fontSize="sm">We need {GROUP_BUY_GOAL} participants to proceed with the order.</Text>
                    </Box>

                    {/* Pledge Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>Make Your Pledge</Heading>
                        <Text mb={4}>Choose the number of units (1 unit = 1 kg):</Text>
                        <Slider
                            aria-label="unit-slider"
                            defaultValue={1}
                            min={1}
                            max={MAX_UNITS_PER_HEAD}
                            step={1}
                            onChange={(val) => setUnitCount(val)}
                            mb={8}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                        <Text fontSize="lg" mb={4}>
                            Your pledge: ${AUD_PRICE * unitCount} AUD ≈ {btcAmount.toFixed(8)} BTC
                        </Text>
                        <Text fontSize="sm" mb={4}>
                            Current BTC Price: 1 BTC = ${btcPriceLoading ? 'Loading...' : btcPrice.toFixed(2)} AUD
                        </Text>
                        <Checkbox
                            isChecked={agreeToTerms}
                            onChange={(e) => setAgreeToTerms(e.target.checked)}
                            mb={4}
                        >
                            I agree to the trial terms and conditions
                        </Checkbox>
                        <Button
                            colorScheme="orange"
                            size="lg"
                            onClick={handlePledge}
                            isDisabled={!agreeToTerms || campaignData?.getCampaign.status !== 'ACTIVE'}
                            width="full"
                        >
                            Pledge Now with Bitcoin Lightning
                        </Button>
                    </Box>

                    {/* How It Works Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>How It Works</Heading>
                        <List spacing={4}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Pledge: Commit to your desired quantity (minimum 1 kg).
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Reach Goal: We need {GROUP_BUY_GOAL} buyers to proceed with the order.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Confirmation: Once the goal is reached, we'll confirm your order.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Payment: Secure payment options will be provided.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Pickup: Choose your convenient pickup location (Noosa or Woolloongabba).
                            </ListItem>
                        </List>
                    </Box>

                    {/* Pickup Locations Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>Pickup Locations</Heading>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            <Box>
                                <Heading as="h3" size="lg" mb={4}>Noosa</Heading>
                                <Image
                                    src="/location_images/noosa_pickup.jpg"
                                    alt="Noosa Pickup Location"
                                    borderRadius="md"
                                    mb={4}
                                />
                                <Text>Address: 123 Noosa Drive, Noosa Heads, QLD 4567</Text>
                            </Box>
                            <Box>
                                <Heading as="h3" size="lg" mb={4}>Woolloongabba</Heading>
                                <Image
                                    src="/location_images/woolloongabba_pickup.jpg"
                                    alt="Woolloongabba Pickup Location"
                                    borderRadius="md"
                                    mb={4}
                                />
                                <Text>Address: 456 Stanley Street, Woolloongabba, QLD 4102</Text>
                            </Box>
                        </Grid>
                    </Box>

                    {/* Cooking Guide Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>How to Cook Kangaroo Meat</Heading>
                        <Text mb={4}>Kangaroo meat is best served medium-rare to medium. Here's a simple guide:</Text>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={TimeIcon} color={accentColor} />
                                Bring meat to room temperature before cooking.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={InfoIcon} color={accentColor} />
                                Season with salt, pepper, and your favorite herbs.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={TimeIcon} color={accentColor} />
                                Cook in a hot pan or grill for 2-3 minutes each side for steaks.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={TimeIcon} color={accentColor} />
                                Let rest for 5-10 minutes before serving.
                            </ListItem>
                        </List>
                        <Text mt={4} fontStyle="italic">
                            Pro tip: Kangaroo meat is very lean, so avoid overcooking to prevent toughness.
                        </Text>
                    </Box>

                    {/* FAQ Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>Frequently Asked Questions</Heading>
                        <VStack align="stretch" spacing={6}>
                            <Box>
                                <Heading as="h3" size="md" mb={2}>How much does it cost to drive to the pickup location?</Heading>
                                <Text>Costs may vary depending on your location. We've chosen central pickup points to minimize travel for most participants.</Text>
                            </Box>
                            <Box>
                                <Heading as="h3" size="md" mb={2}>Where do you source the kangaroo meat from?</Heading>
                                <Text>We source our kangaroo meat from ethical suppliers near Dreamworld. This ensures freshness and supports local businesses.</Text>
                            </Box>
                            <Box>
                                <Heading as="h3" size="md" mb={2}>How long can I store the frozen kangaroo meat?</Heading>
                                <Text>Properly stored in a freezer, the meat can last up to 6-12 months.</Text>
                            </Box>
                            <Box>
                                <Heading as="h3" size="md" mb={2}>Is kangaroo meat environmentally friendly?</Heading>
                                <Text>Yes, kangaroo meat has a lower environmental impact compared to traditional farmed meats.</Text>
                            </Box>
                        </VStack>
                    </Box>

                    {/* Call-to-Action Section */}
                    <Box borderRadius="lg" p={8} bg={cardBgColor} boxShadow="xl" color={textColor}>
                        <Heading as="h2" size="xl" mb={6}>Join Our Food Resilience Movement</Heading>
                        <Text fontSize="lg" mb={6}>
                            By participating in this group buy, you're not just getting high-quality, ethically sourced meat – you're supporting a movement towards more sustainable and resilient local food systems.
                        </Text>
                        <Button
                            colorScheme="orange"
                            size="lg"
                            onClick={handlePledge}
                            isDisabled={!agreeToTerms || campaignData?.getCampaign.status !== 'ACTIVE'}
                            width="full"
                            mb={6}
                        >
                            Pledge Your Support Now
                        </Button>
                        <SMSNotificationSignup buttonText="Get Trial Updates via SMS" buttonColorScheme="orange" />
                    </Box>
                </VStack>
            </Container>

            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box>
    );
};

export default TrialKangarooGroupBuy;