import React, { useEffect } from 'react';
import {
    Box, Container, Heading, Text, VStack, Flex, useColorModeValue,
    List, ListItem, ListIcon, Button, keyframes, Link as ChakraLink,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { FaSeedling, FaCarrot, FaUsers, FaLaptopCode, FaBalanceScale } from 'react-icons/fa';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

interface LevelProps {
    title: string;
    description: string;
    responsibilities: string[];
    skills: string[];
    resources: string[];
    gradientStart: string;
    gradientEnd: string;
    icon: React.ElementType;
    width: string;
}

const Level = ({ title, description, responsibilities, skills, resources, gradientStart, gradientEnd, icon: Icon, width }: LevelProps) => (
    <Box
        bg={`linear-gradient(135deg, ${gradientStart}, ${gradientEnd})`}
        p={8}
        borderRadius="lg"
        boxShadow="2xl"
        width={width}
        mb={6}
        transition="all 0.3s"
        _hover={{
            transform: 'translateY(-5px)',
            boxShadow: '3xl',
        }}
    >
        <Flex align="center" mb={4}>
            <Box as={Icon} size="40px" color="white" mr={4} />
            <Heading as="h3" size="lg" color="white">
                {title}
            </Heading>
        </Flex>
        <Text color="white" mb={6}>{description}</Text>
        <VStack align="start" spacing={6} color="white">
            <Box>
                <Heading as="h4" size="md" mb={3}>Key Responsibilities:</Heading>
                <List spacing={2}>
                    {responsibilities.map((item, index) => (
                        <ListItem key={index}>
                            <ListIcon as={ChevronRightIcon} color="white" />
                            {item}
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box>
                <Heading as="h4" size="md" mb={3}>Required Skills:</Heading>
                <List spacing={2}>
                    {skills.map((item, index) => (
                        <ListItem key={index}>
                            <ListIcon as={ChevronRightIcon} color="white" />
                            {item}
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box>
                <Heading as="h4" size="md" mb={3}>Learning Resources:</Heading>
                <List spacing={2}>
                    {resources.map((item, index) => (
                        <ListItem key={index}>
                            <ListIcon as={ChevronRightIcon} color="white" />
                            {item}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </VStack>
    </Box>
);

const CultivatingResilienceInfographic = () => {
    const bgColor = useColorModeValue('green.50', 'green.900');
    const cardBgColor = useColorModeValue('white', 'green.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('green.500', 'green.300');
    const borderColor = useColorModeValue('green.200', 'green.600');
    const anotherColor = useColorModeValue('green.100', 'green.700');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const levels = [
        {
            title: "Ecosystem Harmonizer",
            description: "Integrates various aspects of resilience, ensures balance in the community ecosystem.",
            responsibilities: ["Facilitate holistic decision-making", "Coordinate inter-level activities", "Develop long-term resilience strategies"],
            skills: ["Systems thinking", "Strategic planning", "Conflict resolution"],
            resources: ["Advanced ecology courses", "Community leadership workshops", "Resilience planning guides"],
            gradientStart: "#1A5F7A",
            gradientEnd: "#57C5B6",
            icon: FaBalanceScale,
            width: "60%"
        },
        {
            title: "Resilience Technologist",
            description: "Implements and maintains transparent, resilient technologies integrated with natural systems.",
            responsibilities: ["Develop resilient tech solutions", "Ensure tech transparency", "Integrate tech with natural systems"],
            skills: ["Sustainable technology expertise", "Open-source development", "Ecological systems understanding"],
            resources: ["Permaculture design courses", "Open-source software development", "Biomimicry workshops"],
            gradientStart: "#159895",
            gradientEnd: "#1A5F7A",
            icon: FaLaptopCode,
            width: "70%"
        },
        {
            title: "Community Connector",
            description: "Facilitates local networks, organizes events, and builds connections for community resilience.",
            responsibilities: ["Organize community events", "Facilitate knowledge sharing", "Build local partnerships"],
            skills: ["Event planning", "Network facilitation", "Public speaking"],
            resources: ["Community organizing guides", "Social network analysis tools", "Public speaking courses"],
            gradientStart: "#1A8F54",
            gradientEnd: "#159895",
            icon: FaUsers,
            width: "80%"
        },
        {
            title: "Local Food Guardian",
            description: "Manages local food production, understanding nutrition and community needs.",
            responsibilities: ["Coordinate local food production", "Educate on nutrition", "Manage food distribution"],
            skills: ["Agricultural knowledge", "Nutritional expertise", "Supply chain management"],
            resources: ["Organic farming courses", "Nutrition and dietetics programs", "Local food systems workshops"],
            gradientStart: "#4E9F3D",
            gradientEnd: "#1A8F54",
            icon: FaCarrot,
            width: "90%"
        },
        {
            title: "Soil and Social Foundations Steward",
            description: "Cultivates awareness of the interconnected health of soil, relationships, and social structures. Recognizes the current state of these foundational elements and practices gratitude for every moment.",
            responsibilities: [
                "Observe and acknowledge the state of soil health in local environments",
                "Reflect on the quality of personal and community relationships",
                "Recognize imbalances in social structures and family dynamics",
                "Practice daily gratitude for the present moment and existing foundations",
                "Cultivate active listening skills to strengthen interpersonal connections"
            ],
            skills: [
                "Mindfulness and present-moment awareness",
                "Basic soil observation and testing",
                "Emotional intelligence and empathy",
                "Conflict recognition in relationships and communities",
                "Gratitude journaling and reflection"
            ],
            resources: [
                "Introduction to soil health and ecosystem courses",
                "Mindfulness and meditation workshops",
                "Relationship and family dynamics seminars",
                "Community listening circles and dialogue facilitation training",
                "Gratitude practice guides and journaling workshops"
            ],
            gradientStart: "#8B7E74",
            gradientEnd: "#4E9F3D",
            icon: FaSeedling,
            width: "100%"
        }
    ];
    return (
        <Box bg={bgColor}>
            <NavbarWithCallToAction />

            <Box bg={bgColor} minHeight="100vh" py={12}>
                <Container maxW="container.xl">
                    <VStack spacing={12} align="stretch">
                        <Box>
                            <Heading as="h1" size="2xl" mb={4} color={textColor}>
                                Cultivating Resilience: Community Leadership Pipeline
                            </Heading>
                            <Text fontSize="xl" mb={6} color={textColor}>
                                A nature-based community leadership structure
                            </Text>
                        </Box>

                        <VStack align="stretch" spacing={8}>
                            {levels.map((level, index) => (
                                <Level key={index} {...level} />
                            ))}
                        </VStack>

                        <Box mt={12}>
                            <Text fontSize="lg" color={textColor} mb={6}>
                                This structure emphasizes that all levels are interconnected, rooted in nature's wisdom.
                                The progression is about expanding one's understanding and ability to support the entire community ecosystem.
                            </Text>
                            <ChakraLink href="/trialmembership">
                                <Button
                                    colorScheme="green"
                                    size="lg"
                                    animation={`${pulseAnimation} 2s infinite`}
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                >
                                    Join Our Community
                                </Button>

                            </ChakraLink>
                        </Box>
                    </VStack>
                </Container>
            </Box>

            <FooterWithFourColumns />
        </Box>
    );
};

export default CultivatingResilienceInfographic;