import React, { useState } from 'react';
import {
    Box, Button, Container, Heading, Text, VStack, HStack, SimpleGrid,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    FormControl, FormLabel, Input, useToast, Image, Stack, Flex, Icon,
    Badge, Avatar, useColorModeValue, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, AccordionIcon, Divider, List, ListItem,
    ListIcon
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { CheckCircleIcon, StarIcon, TimeIcon, CalendarIcon } from '@chakra-ui/icons';
import { FaHeart, FaComments, FaUsers, FaBook, FaMedal, FaLightbulb } from 'react-icons/fa';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';

console.log('🎨 Rendering Enhanced Relationship Sales Page');

// todo: support: add registartion record to the database


// 1. Update your frontend mutation definition to match the schema
const CREATE_RELATIONSHIP_REGISTRATION = gql`
  mutation CreateRelationshipsCourseRegistration(
    $fName: String!
    $lName: String!
    $phoneNumber: String!
    $email: String!
  ) {
    createRelationshipsCourseRegistration(
      fName: $fName
      lName: $lName
      phoneNumber: $phoneNumber
      email: $email
    ) {
      id
      fName
      lName
      phoneNumber
      email
      createdAt
      updatedAt
    }
  }
`;

const RelationshipSalesPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [formData, setFormData] = useState({
        fName: '',
        lName: '',
        phoneNumber: '',
        email: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [createRegistration, { loading: mutationLoading }] = useMutation(
        CREATE_RELATIONSHIP_REGISTRATION,
        {
            onCompleted: (data) => {
                console.log('✅ Registration successful:', data);
                setShowPaymentDetails(true);
                setIsModalOpen(false);
                toast({
                    title: "Registration Successful",
                    description: "Please proceed with payment to complete your enrollment.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            },
            onError: (error) => {
                console.error('❌ Registration error:', error);
                toast({
                    title: "Registration Failed",
                    description: error.message || "Please try again later.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    );

    const toast = useToast();

    // Color scheme
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const accentColor = useColorModeValue('purple.500', 'purple.300');
    const textColor = useColorModeValue('gray.600', 'gray.200');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    // Course modules data
    const modules = [
        {
            title: "Building Love Maps",
            description: "Develop a deeper understanding of your partner's world",
            topics: [
                "Understanding your partner's inner world",
                "Creating emotional connection",
                "Building trust through knowledge",
                "Weekly practical exercises"
            ],
            icon: FaHeart
        },
        {
            title: "Fondness and Admiration",
            description: "Strengthen the positive aspects of your relationship",
            topics: [
                "Expressing appreciation",
                "Building respect",
                "Daily gratitude practices",
                "Recognition exercises"
            ],
            icon: FaUsers
        },
        {
            title: "Turning Towards",
            description: "Learn to recognize and respond to emotional bids",
            topics: [
                "Emotional awareness",
                "Active listening skills",
                "Response techniques",
                "Building emotional bank accounts"
            ],
            icon: FaComments
        },
        {
            title: "Managing Conflict",
            description: "Navigate disagreements with grace and understanding",
            topics: [
                "Conflict resolution strategies",
                "De-escalation techniques",
                "Problem-solving framework",
                "Communication tools"
            ],
            icon: FaLightbulb
        },
        {
            title: "Making Life Dreams Come True",
            description: "Create shared meaning and support each other's aspirations",
            topics: [
                "Goal setting as a couple",
                "Supporting individual growth",
                "Creating shared visions",
                "Action planning"
            ],
            icon: FaMedal
        },
        {
            title: "Creating Shared Meaning",
            description: "Build a deeper connection through shared values and goals",
            topics: [
                "Identifying core values",
                "Creating family traditions",
                "Building shared goals",
                "Ritual development"
            ],
            icon: FaBook
        }
    ];

    // Testimonials data
    const testimonials = [
        {
            name: "Sarah & James",
            role: "Married 5 years",
            content: "This course transformed our relationship. We learned to truly understand each other.",
            avatar: "/placeholder/avatar1.jpg"
        },
        {
            name: "Michael & Lisa",
            role: "Together 3 years",
            content: "The practical tools and exercises made a real difference in how we communicate.",
            avatar: "/placeholder/avatar2.jpg"
        }
    ];

    const handleRegistration = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('📝 Starting registration process with data:', formData);

        // Basic validation
        if (!formData.fName || !formData.lName || !formData.phoneNumber || !formData.email) {
            console.warn('⚠️ Validation failed: Missing required fields');
            toast({
                title: "Validation Error",
                description: "Please fill in all required fields",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsSubmitting(true);

        try {
            console.log('🔄 Submitting registration to backend...');
            const response = await createRegistration({
                variables: {
                    fName: formData.fName,
                    lName: formData.lName,
                    phoneNumber: formData.phoneNumber,
                    email: formData.email
                }
            });

            console.log('✅ Registration successful:', response.data);

            toast({
                title: "Registration Successful",
                description: "Please proceed with payment to complete your enrollment.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            setShowPaymentDetails(true);
            setIsModalOpen(false);
        } catch (error: any) {
            console.error('❌ Registration failed:', error);
            toast({
                title: "Registration Failed",
                description: error.message || "An unexpected error occurred. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePaymentConfirmation = async () => {
        console.log('💰 Processing payment confirmation');
        try {
            // Payment notification logic here
            toast({
                title: "Payment Marked as Complete",
                description: "We'll confirm your payment and add you to the course.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('❌ Payment confirmation error:', error);
        }
    };

    return (
        <Box>
            <NavbarWithCallToAction />


            <Box>
                {/* Hero Section */}
                <Box
                    bg={useColorModeValue('purple.50', 'purple.900')}
                    pt={20}
                    pb={16}
                >
                    <Container maxW="container.xl">
                        <Stack
                            direction={{ base: 'column', lg: 'row' }}
                            spacing={8}
                            alignItems="center"
                        >
                            <Box flex={1}>
                                <Badge
                                    colorScheme="purple"
                                    fontSize="sm"
                                    mb={4}
                                >
                                    The Gottman Method
                                </Badge>
                                <Heading
                                    as="h1"
                                    size="2xl"
                                    mb={6}
                                    color={useColorModeValue('gray.900', 'white')}
                                >
                                    Transform Your Relationship with Science-Based Techniques
                                </Heading>
                                <Text
                                    fontSize="xl"
                                    mb={8}
                                    color={textColor}
                                >
                                    Join our 8-week intensive program based on 40+ years of research by the Gottman Institute. Learn proven strategies to strengthen your relationship and create lasting love.
                                </Text>
                                <Button
                                    size="lg"
                                    colorScheme="purple"
                                    onClick={() => setIsModalOpen(true)}
                                    rightIcon={<Icon as={CalendarIcon} />}
                                >
                                    Reserve Your Spot Now
                                </Button>
                            </Box>
                            <Box flex={1}>
                                <Image
                                    src="/course_images/relationships/7P-Text-Logo-1.png"
                                    alt="7 principles"
                                    borderRadius="xl"
                                    shadow="2xl"
                                    maxW={{ base: '100%', lg: '80%' }}
                                />
                            </Box>
                        </Stack>
                    </Container>
                </Box>

                {/* Stats Section */}
                <Box py={16} bg={cardBgColor}>
                    <Container maxW="container.xl">
                        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={8}>
                            {[
                                { number: "40+", label: "Years of Research" },
                                { number: "3000+", label: "Couples Studied" },
                                { number: "94%", label: "Success Rate" },
                                { number: "8", label: "Weekly Sessions" }
                            ].map((stat, index) => (
                                <Box
                                    key={index}
                                    textAlign="center"
                                    p={6}
                                    borderRadius="lg"
                                    border="1px"
                                    borderColor={borderColor}
                                >
                                    <Text fontSize="4xl" fontWeight="bold" color={accentColor}>
                                        {stat.number}
                                    </Text>
                                    <Text color={textColor}>{stat.label}</Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Container>
                </Box>

                {/* Course Benefits */}
                <Box py={16}>
                    <Container maxW="container.xl">
                        <Heading textAlign="center" mb={12}>Why Choose Our Course?</Heading>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                            {[
                                {
                                    title: "Evidence-Based Approach",
                                    description: "Built on decades of research by the Gottman Institute",
                                    icon: FaBook
                                },
                                {
                                    title: "Practical Tools",
                                    description: "Learn actionable techniques you can apply immediately",
                                    icon: FaLightbulb
                                },
                                {
                                    title: "Expert Facilitation",
                                    description: "Led by certified Gottman Method practitioners",
                                    icon: FaUsers
                                }
                            ].map((benefit, index) => (
                                <Box
                                    key={index}
                                    p={6}
                                    borderRadius="lg"
                                    bg={cardBgColor}
                                    shadow="md"
                                >
                                    <Icon
                                        as={benefit.icon}
                                        w={10}
                                        h={10}
                                        color={accentColor}
                                        mb={4}
                                    />
                                    <Heading size="md" mb={4}>{benefit.title}</Heading>
                                    <Text color={textColor}>{benefit.description}</Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Container>
                </Box>

                {/* Course Modules */}
                <Box py={16} bg={useColorModeValue('gray.50', 'gray.900')}>
                    <Container maxW="container.xl">
                        <Heading textAlign="center" mb={12}>Course Curriculum</Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                            {modules.map((module, index) => (
                                <Box
                                    key={index}
                                    p={6}
                                    borderRadius="lg"
                                    bg={cardBgColor}
                                    shadow="md"
                                >
                                    <HStack mb={4}>
                                        <Icon as={module.icon} w={6} h={6} color={accentColor} />
                                        <Heading size="md">{module.title}</Heading>
                                    </HStack>
                                    <Text color={textColor} mb={4}>{module.description}</Text>
                                    <List spacing={3}>
                                        {module.topics.map((topic, topicIndex) => (
                                            <ListItem key={topicIndex}>
                                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                                {topic}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Container>
                </Box>

                {/* Testimonials */}
                <Box py={16}>
                    <Container maxW="container.xl">
                        <Heading textAlign="center" mb={12}>What Couples Say</Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                            {testimonials.map((testimonial, index) => (
                                <Box
                                    key={index}
                                    p={8}
                                    borderRadius="lg"
                                    bg={cardBgColor}
                                    shadow="md"
                                >
                                    <Text fontSize="lg" mb={4}>"{testimonial.content}"</Text>
                                    <HStack>
                                        <Avatar src={testimonial.avatar} />
                                        <Box>
                                            <Text fontWeight="bold">{testimonial.name}</Text>
                                            <Text fontSize="sm" color={textColor}>{testimonial.role}</Text>
                                        </Box>
                                    </HStack>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Container>
                </Box>

                {/* FAQ Section */}
                <Box py={16} bg={useColorModeValue('gray.50', 'gray.900')}>
                    <Container maxW="container.xl">
                        <Heading textAlign="center" mb={12}>Frequently Asked Questions</Heading>
                        <Accordion allowMultiple>
                            {[
                                {
                                    question: "How is the course delivered?",
                                    answer: "The course is delivered through weekly online sessions, supplemented with mobile SMS support and course materials."
                                },
                                {
                                    question: "Who is this course for?",
                                    answer: "This course is ideal for couples at any stage who want to strengthen their relationship, whether you're just starting out or have been together for years."
                                },
                                {
                                    question: "What makes this different from counseling?",
                                    answer: "Our course provides structured, evidence-based training with practical tools and exercises, often proving more effective than traditional counseling approaches."
                                }
                            ].map((faq, index) => (
                                <AccordionItem key={index}>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Text fontWeight="bold">{faq.question}</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        {faq.answer}
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Container>
                </Box>

                {/* Call to Action */}
                <Box py={16} bg={accentColor}>
                    <Container maxW="container.xl" textAlign="center">
                        <Heading color="white" mb={6}>Ready to Transform Your Relationship?</Heading>
                        <Text color="white" fontSize="xl" mb={8}>
                            Join our next cohort and start your journey to a stronger, more fulfilling relationship.
                        </Text>
                        <Button
                            size="lg"
                            colorScheme="white"
                            variant="solid"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Register Now
                        </Button>
                    </Container>
                </Box>

                {/* Registration Modal */}
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Register for the Course</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <form onSubmit={handleRegistration}>
                                <VStack spacing={4}>
                                    <FormControl isRequired>
                                        <FormLabel>First Name</FormLabel>
                                        <Input
                                            onChange={(e) => setFormData({ ...formData, fName: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Last Name</FormLabel>
                                        <Input
                                            onChange={(e) => setFormData({ ...formData, lName: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Phone Number</FormLabel>
                                        <Input
                                            onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            type="email"
                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                        />
                                    </FormControl>
                                    <Button type="submit" colorScheme="blue" width="full">
                                        Register
                                    </Button>
                                </VStack>
                            </form>
                        </ModalBody>
                    </ModalContent>
                </Modal>

                {/* Payment Details */}
                {showPaymentDetails && (
                    <Box
                        position="fixed"
                        bottom={0}
                        left={0}
                        right={0}
                        p={4}
                        bg={cardBgColor}
                        boxShadow="lg"
                    >
                        <Container maxW="container.xl">
                            <VStack spacing={4}>
                                <Heading size="md">Complete Your Registration</Heading>
                                <Text>Please make payment to:</Text>
                                <Box>
                                    <Text>Account Name: [Account Name]</Text>
                                    <Text>BSB: [BSB]</Text>
                                    <Text>Account Number: [Account Number]</Text>
                                    <Text>Reference: {`${formData.fName} ${formData.lName}`}</Text>
                                </Box>
                                <Button
                                    colorScheme="blue"
                                    onClick={handlePaymentConfirmation}
                                >
                                    I've Made the Payment
                                </Button>
                            </VStack>
                        </Container>
                    </Box>
                )}

                <FooterWithFourColumns />
            </Box>

        </Box >
    );
};

export default RelationshipSalesPage;