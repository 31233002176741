import React, { useEffect } from 'react';
import {
    Box, Container, Heading, Text, VStack, UnorderedList, ListItem,
    useColorModeValue, Button, Image, Flex, Badge, Link as ChakraLink,
    Center
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const TrialMembership = () => {
    const bgColor = useColorModeValue('blue.50', 'blue.900');
    const textColor = useColorModeValue('gray.800', 'white');
    const buttonColorScheme = useColorModeValue('blue', 'green');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box bg={"#EFEDDC"} minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="800px" py={12}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl" color={textColor} textAlign="center">
                        Life Essentials Club Trial Membership
                    </Heading>

                    <Box textAlign="center">
                        <Badge colorScheme="green" fontSize="xl" p={2}>
                            Limited Time Offer!
                        </Badge>
                    </Box>

                    <Text fontSize="xl" fontWeight="bold" color={textColor} textAlign="center">
                        Be one of the first 100 members and get a FREE 1-year membership!
                    </Text>

                    <Box borderWidth={1} borderRadius="lg" p={6}>
                        <VStack spacing={4} align="stretch">
                            <Heading as="h2" size="lg" color={textColor}>
                                Membership Benefits:
                            </Heading>
                            <UnorderedList spacing={2} pl={4} color={textColor}>
                                <ListItem>Access to exclusive life essentials products</ListItem>
                                <ListItem>Significant discounts on essential items</ListItem>
                                <ListItem>Transparent supply chain information</ListItem>
                                <ListItem>Direct relationships with producers</ListItem>
                                <ListItem>Participate in group-buys for better prices</ListItem>
                                <ListItem>Support local and ethical producers</ListItem>
                            </UnorderedList>
                        </VStack>
                    </Box>

                    <Text color={textColor}>
                        After the first 100 members, the annual membership fee will be just $65.
                        This is a small price to pay for access to high-quality, ethically sourced
                        life essentials and the opportunity to be part of a community that's
                        changing the way we shop and consume.
                    </Text>

                    <Box>
                        <Heading as="h3" size="lg" color={textColor} mb={4}>
                            Why We're Doing This
                        </Heading>
                        <Text color={textColor}>
                            We're offering this trial period to build our network, refine our
                            offerings, and create a strong community of conscious consumers and
                            ethical producers. Your support and feedback during this phase will
                            be invaluable in shaping the future of Life Essentials Club.
                        </Text>
                    </Box>

                    <Box>
                        <Heading as="h3" size="lg" color={textColor} mb={4}>
                            Our Vision
                        </Heading>
                        <Text color={textColor}>
                            We're building a platform that connects you directly with producers,
                            following the RICO model. This means more transparent supply chains,
                            fairer prices, and the opportunity to build meaningful relationships
                            with the people who produce your essential goods.
                        </Text>
                    </Box>

                    {/* Banner section */}
                    <Box width="100%" mt={10} px={4}> {/* Added top margin and horizontal padding */}
                        <Center>
                            <Image
                                src="/headder.png"
                                alt="Life Essentials Club Banner"
                                maxWidth={{
                                    base: "90%", // On mobile devices
                                    md: "50%"    // On medium devices and up
                                }}
                                height="auto"
                                objectFit="contain"
                            />
                        </Center>
                    </Box>


                    <Box textAlign="center" pt={8}>
                        <ChakraLink href="/login" color={textColor}>
                            <Button
                                colorScheme={buttonColorScheme}
                                size="lg"
                                fontWeight="bold"
                            // onClick={() => {
                            //     // Add membership sign-up logic here
                            //     // alert("Membership sign-up functionality to be implemented");
                            // }}
                            >
                                Join Now - Limited Free Memberships Available!
                            </Button>
                        </ChakraLink>
                    </Box>

                    <Text fontSize="sm" color={textColor} textAlign="center">
                        By joining, you're not just becoming a member – you're becoming part of a
                        movement towards more ethical, sustainable, and community-focused consumption.
                    </Text>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default TrialMembership;